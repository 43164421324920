var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <textarea\n        id=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\"\n        name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\"\n        rows=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rows") : depth0), depth0))
    + "\"\n        cols=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"cols") : depth0), depth0))
    + "\"\n"
    + ((stack1 = __default(require("../js/helpers/ifanswer.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"dk_id") : depth0),"on",{"name":"ifanswer","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":9,"column":21}}})) != null ? stack1 : "")
    + "        aria-labelledby=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "label\"\n        tabindex=\"0\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"error_message") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + "    >"
    + alias2(__default(require("../js/helpers/ans.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"answer") : depth0),(depth0 != null ? lookupProperty(depth0,"input_id") : depth0),{"name":"ans","hash":{},"data":data,"loc":{"start":{"line":17,"column":5},"end":{"line":17,"column":28}}}))
    + "</textarea>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "        readonly=\"readonly\"\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        class=\"alert-error\"\n        aria-invalid=\"true\"\n        aria-describedby=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "_note\"\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <input\n        id=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\"\n        name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\"\n"
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"password",{"name":"ifequal","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":26,"column":20}}})) != null ? stack1 : "")
    + "        value=\""
    + alias2(__default(require("../js/helpers/ans.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"answer") : depth0),(depth0 != null ? lookupProperty(depth0,"input_id") : depth0),{"name":"ans","hash":{},"data":data,"loc":{"start":{"line":27,"column":15},"end":{"line":27,"column":38}}}))
    + "\"\n"
    + ((stack1 = __default(require("../js/helpers/ifanswer.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"dk_id") : depth0),"on",{"name":"ifanswer","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":30,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"prompt") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":39,"column":15}}})) != null ? stack1 : "")
    + "        tabindex=\"0\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"error_message") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":45,"column":15}}})) != null ? stack1 : "")
    + "        >\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "            type=\"password\"\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "            type=\"text\"\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "            readonly=\"readonly\"\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),"open",{"name":"ifequal","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":36,"column":24}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                aria-labelledby=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "-label r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "-prompt-label\"\n";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                aria-labelledby=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "-label\"\n";
},"18":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            aria-labelledby=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "-label\"\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        class=\"alert alert-error\"\n        aria-invalid=\"true\"\n        aria-describedby=\"error-"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\"\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"rows") : depth0),">",1,{"name":"ifcond","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":47,"column":11}}})) != null ? stack1 : "");
},"useData":true});