var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"response-label\"";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"skinny") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":8,"column":9}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <span class=\"label-inner label-inner-input-text\">\n    <span class=\"label-text\" aria-hidden=\"true\">"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"text") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":5,"column":48},"end":{"line":5,"column":91}}})) != null ? stack1 : "")
    + "</span>\n  </span>\n  <div class=\"input-wrapper\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "&nbsp;";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    size=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"input_width") : depth0), depth0))
    + "\"\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "    oninput=\"this.value=this.value.replace(/[^0-9]/g,'')\"\n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    data-xor-group=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"category_name") : depth0), depth0))
    + "\"\n";
},"15":function(container,depth0,helpers,partials,data) {
    return " hidden-response";
},"17":function(container,depth0,helpers,partials,data) {
    return " grid-input-error";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../js/helpers/ifcond.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"input_type") : depth0),"!=","number",{"name":"ifcond","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":28,"column":4},"end":{"line":35,"column":15}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\"\n"
    + ((stack1 = __default(require("../js/helpers/ifans.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answer") : depth0),(depth0 != null ? lookupProperty(depth0,"name") : depth0),(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"ifans","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":32,"column":14}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    return "    checked=\"checked\"\n";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    value=\""
    + container.escapeExpression(__default(require("../js/helpers/answer.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"name") : depth0),(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"answer","hash":{},"data":data,"loc":{"start":{"line":34,"column":11},"end":{"line":34,"column":31}}}))
    + "\"\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    aria-label=\""
    + alias1(__default(require("../js/helpers/getText.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"label_cat") : depth0),{"name":"getText","hash":{},"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":38,"column":37}}}))
    + ": "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"label_resp") : depth0)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "\"\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "    readonly=\"readonly\"\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"skinny") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":2},"end":{"line":47,"column":9}}})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    return "  </div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"skinny") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":2},"end":{"line":54,"column":9}}})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <span class=\"label-inner\">\n    <span class=\"label-text\" aria-hidden=\"true\">"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"text") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":52,"column":48},"end":{"line":52,"column":91}}})) != null ? stack1 : "")
    + "</span>\n  </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label for=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"skinny") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":33},"end":{"line":1,"column":76}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = __default(require("../js/helpers/ifcond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"input_type") : depth0),"==","text",{"name":"ifcond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "\n  <input\n    id=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\"\n    type=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_type") : depth0), depth0))
    + "\"\n    data-column=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\"\n    name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n    max=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"colsum") : depth0), depth0))
    + "\"\n"
    + ((stack1 = __default(require("../js/helpers/ifcond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"input_type") : depth0),"==","text",{"name":"ifcond","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../js/helpers/ifcond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"input_type") : depth0),"==","number",{"name":"ifcond","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":22,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"input_type") : depth0),"checkbox",{"name":"ifequal","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":25,"column":16}}})) != null ? stack1 : "")
    + "    class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"dk_id") : depth0), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"label_resp") : depth0)) != null ? lookupProperty(stack1,"input_class") : stack1), depth0))
    + ((stack1 = __default(require("../js/helpers/ifanswer.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"dk_id") : depth0),"on",{"name":"ifanswer","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":47},"end":{"line":26,"column":100}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../js/helpers/ifcond.js")).call(alias3,__default(require("../js/helpers/answer.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"name") : depth0),(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"answer","hash":{},"data":data,"loc":{"start":{"line":26,"column":110},"end":{"line":26,"column":128}}}),">",(depth0 != null ? lookupProperty(depth0,"colsum") : depth0),{"name":"ifcond","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":100},"end":{"line":26,"column":169}}})) != null ? stack1 : "")
    + "\"\n"
    + ((stack1 = __default(require("../js/helpers/ifcond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"input_type") : depth0),"!=","text",{"name":"ifcond","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":4},"end":{"line":36,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"label_cat") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":4},"end":{"line":39,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../js/helpers/ifanswer.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"dk_id") : depth0),"on",{"name":"ifanswer","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":4},"end":{"line":42,"column":17}}})) != null ? stack1 : "")
    + "  >\n"
    + ((stack1 = __default(require("../js/helpers/ifcond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"input_type") : depth0),"==","text",{"name":"ifcond","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":0},"end":{"line":48,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../js/helpers/ifcond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"input_type") : depth0),"!=","text",{"name":"ifcond","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":0},"end":{"line":55,"column":11}}})) != null ? stack1 : "")
    + "</label>\n";
},"useData":true});