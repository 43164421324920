(function ($, widgets) {
  function linearEq(c, m, x) {
    return m * x + c;
  }

  function interpolate(min, max, alpha) {
    return min + alpha * (max - min);
  }

  function clamp(value, min, max) {
    return Math.max(min, Math.min(max, value));
  }

  var id = 0;

  var DynamicRowLayout = Gryphon.BaseClass.extend(
    {
      /**
       * Lay out the `elements` in the `container`, dynamically, based on the
       * configuration passed in and the window width.
       *
       * When removing the layout, call `.destroy()` to do the necessary clean
       * up.
       *
       * The configuration specifies:
       *     - columns: the requested number of columns to render
       *     - horizontal: boolean for row-first (true) or column-first (false)
       *       layout
       *     - wrap: boolean for whether intermediate numbers of columns are
       *       rendered before collapsing to 1.
       *     - onLayout: callback to call when the elements are layed out. The
       *       layout instance is passed to this callback.
       *
       * This relies on styling provided by the dynamic-row-layout.less file.
       *
       * @param {object} container jQuery object of the container element in
       *     which to make the layout.
       * @param {object} elements jQuery object of the elements to lay out.
       * @param {object} config Has the form:
       *     { columns, horizontal, wrap, onLayout }
       *     as described above.
       */
      constructor: function DynamicRowLayoutConstructor(
        container,
        elements,
        config
      ) {
        this.elements = elements;
        this.container = container.css('max-width', '100%');
        this._object = $.extend({}, DynamicRowLayout.DEFAULTS, config);

        this._resizeEventName = 'resize.DynamicRowLayout' + id++;

        var _render = $.throttle(
          widgets.RELAYOUT_PERIOD,
          _.bind(this.render, this)
        );
        $(window).on(this._resizeEventName, _render);

        /**
         * Current status of the layout, of the form { columns, windowWidth }.
         * Useful in onLayout callbacks.
         */
        this.status = { columns: null, windowWidth: null };

        /**
         * Current window width. Used for current rendering.
         * @private
         */
        this._windowWidth = null;

        /**
         * Number of rendered columns.
         * @private
         */
        this._columns = null;
      },

      /**
       * Determines whether or not a render should be done.
       *
       * Also prevents infinite resize event loop in oldIE.
       * @private
       */
      _shouldRender: function _shouldRender() {
        var lastWidth = this._windowWidth;
        var width = (this._windowWidth = $(window).width());
        var obj = this._object;
        var minColumns = Math.min(obj.minColumns, this.elements.length);
        var requestedColumns = Math.min(
          Math.max(obj.columns, minColumns),
          this.elements.length
        );
        var columns = this._columnsForViewport(
          requestedColumns,
          minColumns,
          obj.wrap,
          width
        );
        if (this._columns === columns) {
          return this._thresholdCrossed(lastWidth, width);
        }

        this._columns = columns;
        return true;
      },

      _thresholdCrossed: function _thresholdCrossed(lastWidth, newWidth) {
        var lastWasOverThreshold;
        var newIsOverThreshold;
        if (lastWidth == null) {
          return true;
        }

        var thresholds = this._object.thresholds;
        for (var i = 0; i < thresholds.length; i++) {
          lastWasOverThreshold = lastWidth > thresholds[i];
          newIsOverThreshold = newWidth > thresholds[i];
          if (lastWasOverThreshold !== newIsOverThreshold) {
            return true;
          }
        }

        return false;
      },

      render: function render() {
        if (!this._shouldRender()) {
          return;
        }

        this.elements.detach();
        this.container.empty();

        var layout = this._makeLayout(this._columns, this._object.horizontal);
        this.container.append(layout);

        this.status = {
          columns: this._columns,
          windowWidth: this._windowWidth,
        };

        if (this._object.onLayout) {
          this._object.onLayout(this);
        }
      },

      _makeLayout: function _makeLayout(columns, horizontal) {
        var rows = this._arrangeElements(this.elements, columns, horizontal);
        var fragment = document.createDocumentFragment();

        _.each(rows, function (rowOfItems, idx) {
          var rowContainer = document.createElement('div');
          rowContainer.className = 'dynamic-row';
          rowContainer.setAttribute('data-index', idx);

          // In the instance we have a row item on a row with
          // less than the number of expected columns we can
          // fill it with empty divs to help the flexbox
          // display correctly
          if (rowOfItems.length < columns) {
            do {
              rowOfItems.push(document.createElement('div'));
            } while (rowOfItems.length < columns);
          }

          _.each(rowOfItems, function (item, index) {
            var itemContainer = document.createElement('div');
            var className;
            if (index === columns - 1) {
              className = 'dynamic-row-item-1-of-last';
            } else {
              className = 'dynamic-row-item-1-of-' + columns;
            }
            itemContainer.className = className;
            itemContainer.appendChild(item);
            rowContainer.appendChild(itemContainer);
          });

          fragment.appendChild(rowContainer);
        });
        return fragment;
      },

      destroy: function destroy() {
        $(window).off(this._resizeEventName);
      },

      /**
       * Return the appropriate number of columns to render given the number of
       * columns set in the question, the minimum number of columns to render,
       * whether the wrap option has been specified, and the width of the
       * viewport.
       *
       * @private
       * @param {number} requestedColumns Requested number of columns.
       * @param {number} minColumns The minimum number of columns to render
       * @param {boolean} wrap Whether to incrementally adjust the number of
       *     columns in proportion to the viewport width.
       * @param {number} viewportWidth The viewport width.
       */
      _columnsForViewport: function (
        requestedColumns,
        minColumns,
        wrap,
        viewportWidth
      ) {
        var columns;

        if (requestedColumns === minColumns) {
          return requestedColumns;
        }

        // How the number of columns is calculated
        // =======================================
        //
        // First, a warning - it's very easy to make off-by-one errors in this
        // carefully-constructed implementation, which is a stair-stepped linear
        // equation relating device width and requested columns to the number of
        // columns to render.
        //
        // You have been warned!
        //
        // Wrapped case
        // ------------
        //
        // Divide the range of device widths between the minimum (mobile) size
        // and the maximum (desktop) size into N equal ranges, where N is the
        // difference between the minimum number columns and the requested number
        // of columns. Each device width range represents a different number of
        // columns.
        //
        // Below the minimum device width is always the minimum number of
        // columns. The next region above that also represents the minimum number
        // columns. The region after that is one plus the minimum, and so on, up
        // to the last region, which is the requested number of columns. Anything
        // wider than the maximum width just gets the requested number of
        // columns.
        //
        // For example, in the case that 3 columns are requested with a minimum
        // of 1 column:
        //
        //          ^
        //          |
        //          |  3 columns
        //          |
        //          +-------------------+
        //          |                  /|
        //          |  3 columns      / |
        //          |                /  |
        //          |               /   |
        //          +--------------+    |
        //          |             /|    |
        // C        |  2         / |    |
        // o        |           /  |    |
        // l        |          /   |    |
        // u        +---------+    |    |
        // m        |        /|    |    |
        // n        |  1    / |    |    |
        // s        |      /  |    |    |
        //          |     /   |    |    |
        //          +----+    |    |    |
        //          |  1 |    |    |    |
        //          |    |    |    |    |
        //          |    |    |    |    |
        //          |    |    |    |    |
        //          +----+----+----+----+------->
        //             min              max
        //        (small mobile)     (desktop)
        //
        //              Device width
        //
        // Non-wrapped case
        // ----------------
        //
        // This is identical to the wrapped case, except that the minimum number
        // of columns is returned when N-1 or fewer columns would *otherwise*
        // have been returned by the wrapped case.
        var min = widgets.MEDIA_SMALL_DEVICE_MAX_WIDTH;
        var max = widgets.MEDIA_FULL_WIDTH;
        var deltaWidth = max - min;
        var deltaColumns = requestedColumns - minColumns + 1;
        var gradient = deltaColumns / deltaWidth;
        var intercept = requestedColumns - (max * deltaColumns) / deltaWidth;

        var floatColumns = linearEq(intercept, gradient, viewportWidth);

        // Avoid using too many columns due to floating point error
        floatColumns = Number(floatColumns.toPrecision(5));
        if (wrap) {
          columns = Math.ceil(floatColumns);
          columns = clamp(columns, minColumns, requestedColumns);
        } else {
          var alpha = 1;
          var threshold = interpolate(minColumns, requestedColumns - 1, alpha);
          if (floatColumns <= threshold) {
            columns = minColumns;
          } else {
            columns = requestedColumns;
          }
        }

        if (viewportWidth <= widgets.MEDIA_SKINNY_MAX_WIDTH) {
          columns = 1;
        }

        return columns;
      },

      /**
       * Transform 1D array into 2D array.
       *
       * @param {array} items Array-like list of items
       * @param {number} columns The number of columns to render.
       * @param {boolean} horizontal Z (true) or И (false) layout.
       */
      _arrangeElements: function (items, columns, horizontal) {
        if (horizontal) {
          /**
           * Make sure our lodash mixins are loaded into the current lodash context
           * If not, we may have had an external lodash script override them
           */
          if (!_.group) {
            _.mixin({
              group: function group(list, size) {
                var ret = [];
                var i, remainder, subArray;
                for (i = 0; i < list.length; i++) {
                  remainder = i % size;
                  if (remainder === 0) {
                    subArray = [];
                    ret.push(subArray);
                  }
                  subArray.push(list[i]);
                }
                return ret;
              },
            });
          }
          return _.group(items, columns);
        } else {
          /**
           * Make sure our lodash mixins are loaded into the current lodash context
           * If not, we may have had an external lodash script override them
           */
          if (!_.transpose || !_.split) {
            _.mixin({
              split: function split(list, number) {
                var listLength = list.length;
                number = Math.min(listLength, number);
                var size = Math.floor(listLength / number);
                var remainder = listLength % number;
                var ret = [];
                var pointer = 0;
                for (var i = 0; i < number; i++) {
                  var subSize = size;
                  if (remainder) {
                    subSize += 1;
                    remainder -= 1;
                  }
                  ret.push(list.slice(pointer, pointer + subSize));
                  pointer += subSize;
                }
                return ret;
              },
              transpose: function transpose(arrayOfArrays) {
                while (arrayOfArrays.length < 2) {
                  arrayOfArrays.push([]);
                }
                return _.map(_.zip.apply(_, arrayOfArrays), _.compact);
              },
            });
          }
          return _.transpose(_.split(items, columns));
        }
      },
    },
    {
      DEFAULTS: {
        columns: 1,
        minColumns: 1,
        horizontal: false,
        thresholds: [],
        wrap: true,
      },
    }
  );

  widgets.layout = widgets.layout || {};
  widgets.layout.DynamicRowLayout = DynamicRowLayout;
})(jQuery, (Gryphon.widgets = Gryphon.widgets || {}));
