var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answer") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":13,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div role=\"note\" class=\"sr-only\" tabindex=\"0\">\n    Previous answer for \""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "\" item is\n"
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answer") : depth0),997,{"name":"ifequal","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":11,"column":16}}})) != null ? stack1 : "")
    + "  </div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"dk_text") : depths[1]), depth0))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(__default(require("../js/helpers/ans.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answer") : depth0),(depth0 != null ? lookupProperty(depth0,"input_id") : depth0),{"name":"ans","hash":{},"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":10,"column":29}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"instructions text-info\">\n    "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"instructions") : depth0), depth0)) != null ? stack1 : "")
    + " "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"dk_instructions") : depth0), depth0)) != null ? stack1 : "")
    + "\n  </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"instructions text-info\" style=\"margin-bottom: 0px\">\n    "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"instructions") : depth0), depth0)) != null ? stack1 : "")
    + " "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"dk_instructions") : depth0), depth0)) != null ? stack1 : "")
    + "\n  </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./error.hbs"),depth0,{"name":"error","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"color") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.program(19, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":38,"column":37},"end":{"line":131,"column":17}}})) != null ? stack1 : "")
    + " ";
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n          <style>\n"
    + ((stack1 = __default(require("../js/helpers/startsWith.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"color") : depth0),"#",{"name":"startsWith","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":40,"column":10},"end":{"line":48,"column":25}}})) != null ? stack1 : "")
    + "          </style>\n          <span\n            class=\"placement-item placement-user-defined-color placement-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + "\"\n            id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-item\"\n          >\n            <div class=\"slider-indicator\">\n              <div class=\"slider-indicator-line\" style=\"background-color: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + "\"></div>\n              <div class=\"slider-indicator-dot\" style=\"background-color: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + "\"></div>\n            </div>\n            <div\n              class=\"placement-pointer-text-container accessible-dropdown-trigger\"\n            >\n              <div\n                aria-haspopup=\"true\"\n                role=\"button\"\n                title=\"Move "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "\"\n                tabindex=\"0\"\n              >\n                <span class=\"placement-pointer-text\">\n                  <div class=\"placement-ball\"></div>\n                  <div class=\"placement-label\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n                </span>\n              </div>\n              <ul class=\"accessible-dropdown\" role=\"menu\">\n                <li\n                  role=\"menuitem\"\n                  data-source=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-item\"\n                  data-placement=\"drop\"\n                >\n                  Place on scale\n                </li>\n                <li\n                  role=\"menuitem\"\n                  data-source=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-item\"\n                  data-placement=\"notsure\"\n                >\n                  Place on "
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"dk_text") : depths[1]), depth0))
    + "\n                </li>\n              </ul>\n            </div>\n          </span>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          .placement-user-defined-color.placement-\\"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " .placement-ball:before {\n            background-color: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " !important;\n          }\n";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          .placement-user-defined-color.placement-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " .placement-ball:before {\n            background-color: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " !important;\n          }\n";
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <span\n            class=\"placement-item placement-"
    + ((stack1 = __default(require("../js/helpers/lower.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"lower","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":44},"end":{"line":92,"column":87}}})) != null ? stack1 : "")
    + "\"\n            id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-item\"\n          >\n            <div class=\"slider-indicator\">\n              <div class=\"slider-indicator-line\"></div>\n              <div class=\"slider-indicator-dot\"></div>\n            </div>\n            <div\n              class=\"placement-pointer-text-container accessible-dropdown-trigger\"\n            >\n              <div\n                aria-haspopup=\"true\"\n                role=\"button\"\n                title=\"Move "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "\"\n                tabindex=\"0\"\n              >\n                <span class=\"placement-pointer-text\">\n                  <div class=\"placement-ball\"></div>\n                  <div class=\"placement-label\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n                </span>\n              </div>\n              <ul class=\"accessible-dropdown\" role=\"menu\">\n                <li\n                  role=\"menuitem\"\n                  data-source=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-item\"\n                  data-placement=\"drop\"\n                >\n                  Place on scale\n                </li>\n                <li\n                  role=\"menuitem\"\n                  data-source=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-item\"\n                  data-placement=\"notsure\"\n                >\n                  Place on "
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"dk_text") : depths[1]), depth0))
    + "\n                </li>\n              </ul>\n            </div>\n          </span>\n          ";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(__default(require("../js/helpers/item.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"colors") : depths[1]),(depth0 != null ? lookupProperty(depth0,"idx") : depth0),{"name":"item","hash":{},"data":data,"loc":{"start":{"line":92,"column":54},"end":{"line":92,"column":77}}}));
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"placement-notsure-wrapper\" tabindex=\"2\">\n        <div id=\"placement-notsure\">\n          <div id=\"placement-notsure-label\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"dk_text") : depth0), depth0))
    + "</div>\n        </div>\n      </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <input\n    type=\"hidden\"\n    name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\"\n    class=\"placement-values\"\n    id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-value\"\n    value=\""
    + alias2(__default(require("../js/helpers/ans.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answer") : depth0),(depth0 != null ? lookupProperty(depth0,"input_id") : depth0),{"name":"ans","hash":{},"data":data,"loc":{"start":{"line":154,"column":11},"end":{"line":154,"column":34}}}))
    + "\"\n  />\n  ";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n  <span id=\"required\" style=\"display: none; visible: hidden\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"required") : depth0), depth0))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"question question-placement\">\n    <legend class=\"question-text\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-label\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"category_options") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":14,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"legacy") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":15,"column":2},"end":{"line":23,"column":9}}})) != null ? stack1 : "")
    + "\n  <div id=\"placement-container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"error_message") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":28,"column":11}}})) != null ? stack1 : "")
    + "    <script>\n      window.placement_narrow_view = '"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"narrow_view") : depth0), depth0))
    + "'\n      window.placement_wide_view = '"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"wide_view") : depth0), depth0))
    + "'\n    </script>\n    <div\n      class=\"placement-widget placement-outer narrow_view_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"narrow_view") : depth0), depth0))
    + " wide_view_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"wide_view") : depth0), depth0))
    + "\"\n    >\n      <div class=\"placement-wrapper\">\n        <div class=\"placement-options\">\n          "
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"category_options") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":10},"end":{"line":131,"column":27}}})) != null ? stack1 : "")
    + "\n        </div>\n        "
    + ((stack1 = __default(require("../js/helpers/partial.js")).call(alias3,"rule-partial",{"name":"partial","hash":{"show_value":(depth0 != null ? lookupProperty(depth0,"show_value") : depth0),"input_id":(depth0 != null ? lookupProperty(depth0,"input_id") : depth0),"right_message":(depth0 != null ? lookupProperty(depth0,"right_message") : depth0),"left_message":(depth0 != null ? lookupProperty(depth0,"left_message") : depth0),"increment":10,"max":100,"min":0,"widget":"placement"},"data":data,"loc":{"start":{"line":133,"column":8},"end":{"line":135,"column":51}}})) != null ? stack1 : "")
    + "\n      </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"dont_know") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":137,"column":6},"end":{"line":143,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"clearfix\"></div>\n  </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"category_options") : depth0),{"name":"each","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":2},"end":{"line":156,"column":11}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"required") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":156,"column":12},"end":{"line":158,"column":9}}})) != null ? stack1 : "")
    + "</fieldset>\n";
},"usePartial":true,"useData":true,"useDepths":true});