var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./error.hbs"),depth0,{"name":"error","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return " colorpicker-response-list-columns";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"dk-center\">\n    <input type=\"checkbox\" class=\"dk-check\" />\n    <span class=\"dk-label\"><label for=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-dk\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"dk_text") : depth0), depth0)) != null ? stack1 : "")
    + "</label></span>\n  </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"question question-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\">\n  <legend class=\"question-text\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-label\">\n    <span id=\"page-stem\">"
    + ((stack1 = __default(require("../js/helpers/get.js")).call(alias3,__default(require("../js/helpers/get.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"category_options") : depth0),(depth0 != null ? lookupProperty(depth0,"category_index") : depth0),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":3,"column":32},"end":{"line":3,"column":69}}}),"text",{"name":"get","hash":{},"data":data,"loc":{"start":{"line":3,"column":25},"end":{"line":3,"column":79}}})) != null ? stack1 : "")
    + "</span>\n  </legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"error_message") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":2},"end":{"line":7,"column":9}}})) != null ? stack1 : "")
    + "\n  <p class=\"colorpicker-instructions\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"instructions") : depth0), depth0)) != null ? stack1 : "")
    + "</p>\n  <div class=\"question-response-list"
    + ((stack1 = __default(require("../js/helpers/ifcond.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"columns") : depth0),">",1,{"name":"ifcond","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":36},"end":{"line":10,"column":106}}})) != null ? stack1 : "")
    + "\"></div>\n  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"dk") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":2},"end":{"line":17,"column":9}}})) != null ? stack1 : "")
    + "</fieldset>\n\n ";
},"usePartial":true,"useData":true});