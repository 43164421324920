var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " grid-layout-striped";
},"3":function(container,depth0,helpers,partials,data) {
    return "grid-layout-splitlabels";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <thead>\r\n    <tr class=\"grid-row grid-row-"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"response_options") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"header_row") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":21,"column":15}}})) != null ? stack1 : "")
    + "      "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"rowsum") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":6},"end":{"line":22,"column":77}}})) != null ? stack1 : "")
    + "\r\n    </tr>\r\n  </thead>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"phases") : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":2},"end":{"line":123,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"colsum") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":2},"end":{"line":139,"column":9}}})) != null ? stack1 : "")
    + "  ";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"empty") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":20,"column":13}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + ((stack1 = __default(require("../js/helpers/set.js")).call(alias1,(data && lookupProperty(data,"root")),{"name":"set","hash":{"question_column_index":(data && lookupProperty(data,"index"))},"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":7,"column":50}}})) != null ? stack1 : "")
    + "\r\n      <td "
    + ((stack1 = __default(require("../js/helpers/unlessequal.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"resp_width") : depths[1]),"auto",{"name":"unlessequal","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":10,"column":26}}})) != null ? stack1 : "")
    + "          >"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</td>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "\r\n          style=\"width: auto\"\r\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <th "
    + ((stack1 = __default(require("../js/helpers/ifcond.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"resp_width") : depths[1]),"!=","auto",{"name":"ifcond","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":10},"end":{"line":15,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"distinct") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":10},"end":{"line":18,"column":17}}})) != null ? stack1 : "")
    + "          scope=\"col\" class=\"grid-header-response-"
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0)) != null ? stack1 : "")
    + "\">"
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</th>\r\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\r\n          style=\"width: "
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"resp_width") : depths[1]), depth0))
    + ";\"\r\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "          class=\"grid-header-distinct\"\r\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<th class=\"total\" scope=\"col\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"total_label") : depth0), depth0))
    + "</th>";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <tbody id=\"phase-"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\" "
    + ((stack1 = __default(require("../js/helpers/ifnotphase.js")).call(alias3,(depths[1] != null ? lookupProperty(depths[1],"phase_pos") : depths[1]),(data && lookupProperty(data,"index")),{"name":"ifnotphase","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":31},"end":{"line":26,"column":103}}})) != null ? stack1 : "")
    + " data-phase=\""
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,depth0,{"name":"each","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":4},"end":{"line":121,"column":13}}})) != null ? stack1 : "")
    + "  </tbody>\r\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <tr "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"varname") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":28,"column":48}}})) != null ? stack1 : "")
    + ">\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"response_options") : depths[2]),{"name":"each","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":6},"end":{"line":48,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"text") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":6},"end":{"line":60,"column":13}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"response_options") : depths[2]),{"name":"each","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":6},"end":{"line":80,"column":15}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"rowsum") : depths[2]),{"name":"if","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":6},"end":{"line":86,"column":13}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"splitlabels") : depths[2]),{"name":"if","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":6},"end":{"line":97,"column":13}}})) != null ? stack1 : "")
    + "    </tr>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"splitlabels") : depths[2]),{"name":"if","hash":{},"fn":container.program(37, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":4},"end":{"line":120,"column":11}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "id=\"r-"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"varname") : depth0), depth0))
    + "\"";
},"23":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(__default(require("../js/helpers/set.js")).call(alias1,depth0,{"name":"set","hash":{"response_index":(data && lookupProperty(data,"index"))},"data":data,"loc":{"start":{"line":30,"column":6},"end":{"line":30,"column":40}}}))
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"front") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":6},"end":{"line":47,"column":13}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <td class=\"grid-cell open-cell "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"options") : depth0)) != null ? lookupProperty(stack1,"distinct") : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":37},"end":{"line":32,"column":86}}})) != null ? stack1 : "")
    + "\">\r\n        "
    + ((stack1 = __default(require("../js/helpers/partial.js")).call(alias1,"grid-cell-input",{"name":"partial","hash":{"label_resp":__default(require("../js/helpers/get.js")).call(alias1,(depths[3] != null ? lookupProperty(depths[3],"response_options") : depths[3]),(depth0 != null ? lookupProperty(depth0,"response_index") : depth0),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":44,"column":21},"end":{"line":44,"column":67}}}),"label_cat":__default(require("../js/helpers/get.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"text") : depths[1]),0,{"name":"get","hash":{},"data":data,"loc":{"start":{"line":43,"column":20},"end":{"line":43,"column":35}}}),"dk_id":(depths[3] != null ? lookupProperty(depths[3],"dk_id") : depths[3]),"answer":(depths[1] != null ? lookupProperty(depths[1],"answer") : depths[1]),"input_class":(depth0 != null ? lookupProperty(depth0,"input_class") : depth0),"colsum":(depths[3] != null ? lookupProperty(depths[3],"colsum") : depths[3]),"input_width":(depths[3] != null ? lookupProperty(depths[3],"input_width") : depths[3]),"input_type":(depths[3] != null ? lookupProperty(depths[3],"input_type") : depths[3]),"code":(depth0 != null ? lookupProperty(depth0,"code") : depth0),"category_name":(depths[1] != null ? lookupProperty(depths[1],"name") : depths[1]),"name":__default(require("../js/helpers/get.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"response_inputs") : depths[1]),(depth0 != null ? lookupProperty(depth0,"response_index") : depth0),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":34,"column":15},"end":{"line":34,"column":54}}})},"data":data,"loc":{"start":{"line":33,"column":8},"end":{"line":45,"column":11}}})) != null ? stack1 : "")
    + "\r\n      </td>\r\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "grid-cell-distinct";
},"27":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../js/helpers/or.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depths[2] != null ? lookupProperty(depths[2],"header_row") : depths[2])) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"empty") : stack1),(depths[2] != null ? lookupProperty(depths[2],"offset") : depths[2]),{"name":"or","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":6},"end":{"line":59,"column":13}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <th class=\"grid-item-text-"
    + container.escapeExpression(alias1((depths[2] != null ? lookupProperty(depths[2],"cat_text_class") : depths[2]), depth0))
    + "\" scope=\"row\">\r\n        "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"0") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n      </th>\r\n";
},"30":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"front") : depth0),{"name":"unless","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":6},"end":{"line":79,"column":17}}})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    return "      <th class=\"grid-item-text-right total\" scope=\"row\">\r\n        0\r\n      </th>\r\n";
},"34":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <th class=\"grid-item-text-right\"\r\n"
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[2] != null ? lookupProperty(depths[2],"resp_width") : depths[2]),"auto",{"name":"ifequal","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":90,"column":10},"end":{"line":92,"column":22}}})) != null ? stack1 : "")
    + "          scope=\"row\"\r\n          >\r\n        "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"1") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n      </th>\r\n";
},"35":function(container,depth0,helpers,partials,data) {
    return "          style=\"width:16%\"\r\n";
},"37":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../js/helpers/ifskinny.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[2] != null ? lookupProperty(depths[2],"collapse_width") : depths[2]),{"name":"ifskinny","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":4},"end":{"line":119,"column":17}}})) != null ? stack1 : "");
},"38":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <tr class=\"labels\">\r\n      <td colspan=\""
    + ((stack1 = alias1((depths[2] != null ? lookupProperty(depths[2],"ritem_num") : depths[2]), depth0)) != null ? stack1 : "")
    + "\">\r\n        <table>\r\n          <tr>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"text") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":12},"end":{"line":111,"column":19}}})) != null ? stack1 : "")
    + "            <th class=\"grid-item-text-right\" scope=\"row\">\r\n              "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"1") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n            </th>\r\n          </tr>\r\n        </table>\r\n      </td>\r\n    </tr>\r\n";
},"39":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../js/helpers/or.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depths[2] != null ? lookupProperty(depths[2],"header_row") : depths[2])) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"empty") : stack1),(depths[3] != null ? lookupProperty(depths[3],"offset") : depths[3]),{"name":"or","hash":{},"fn":container.program(40, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":12},"end":{"line":110,"column":19}}})) != null ? stack1 : "");
},"40":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <th class=\"grid-item-text-"
    + container.escapeExpression(alias1((depths[2] != null ? lookupProperty(depths[2],"cat_text_class") : depths[2]), depth0))
    + "\" scope=\"row\">\r\n              "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"0") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n            </th>\r\n";
},"42":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <tfoot>\r\n    <tr class=\"info\"> \r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"response_options") : depth0),{"name":"each","hash":{},"fn":container.program(43, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":127,"column":6},"end":{"line":133,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"question_column_index_displayed") : depth0),{"name":"unless","hash":{},"fn":container.program(49, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":134,"column":6},"end":{"line":136,"column":17}}})) != null ? stack1 : "")
    + "    </tr>\r\n  </tfoot>\r\n";
},"43":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"question_column_index") : depths[1]),(data && lookupProperty(data,"index")),{"name":"ifequal","hash":{},"fn":container.program(44, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":128,"column":6},"end":{"line":131,"column":18}}})) != null ? stack1 : "")
    + "        <th class=\"grid-item total\" data-column=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\" scope=\"col\">0</th>\r\n";
},"44":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = __default(require("../js/helpers/set.js")).call(alias1,(data && lookupProperty(data,"root")),{"name":"set","hash":{"question_column_index_displayed":true},"data":data,"loc":{"start":{"line":129,"column":8},"end":{"line":129,"column":60}}})) != null ? stack1 : "")
    + "\r\n        <th class=\"grid-item-text"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"question_column_index") : depths[1]),{"name":"unless","hash":{},"fn":container.program(45, data, 0, blockParams, depths),"inverse":container.program(47, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":130,"column":33},"end":{"line":130,"column":100}}})) != null ? stack1 : "")
    + "\" scope=\"row\">"
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"total_label") : depths[1]), depth0))
    + "</th>\r\n";
},"45":function(container,depth0,helpers,partials,data) {
    return "-left";
},"47":function(container,depth0,helpers,partials,data) {
    return "-middle";
},"49":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <th class=\"grid-item-text-middle\" scope=\"row\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"total_label") : depth0), depth0))
    + "</th>\r\n";
},"51":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\r\n  <thead>\r\n    <tr class=\"grid-row grid-row-"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"category_options") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">\r\n      <td "
    + ((stack1 = __default(require("../js/helpers/unlessequal.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"resp_width") : depths[1]),"auto",{"name":"unlessequal","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":10},"end":{"line":145,"column":26}}})) != null ? stack1 : "")
    + "          ></td>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"phases") : depth0),{"name":"each","hash":{},"fn":container.program(52, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":6},"end":{"line":158,"column":15}}})) != null ? stack1 : "")
    + "    </tr>\r\n  </thead>\r\n\r\n  <tbody> \r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"response_options") : depth0),{"name":"each","hash":{},"fn":container.program(56, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":163,"column":4},"end":{"line":192,"column":13}}})) != null ? stack1 : "")
    + "  </tbody>\r\n  ";
},"52":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + container.escapeExpression(__default(require("../js/helpers/set.js")).call(alias1,depth0,{"name":"set","hash":{"category_index":(data && lookupProperty(data,"index"))},"data":data,"loc":{"start":{"line":148,"column":10},"end":{"line":148,"column":44}}}))
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,depth0,{"name":"each","hash":{},"fn":container.program(53, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":10},"end":{"line":157,"column":19}}})) != null ? stack1 : "");
},"53":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <th "
    + ((stack1 = __default(require("../js/helpers/ifnotphase.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"phase_pos") : depths[1]),(depths[1] != null ? lookupProperty(depths[1],"category_index") : depths[1]),{"name":"ifnotphase","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":150,"column":14},"end":{"line":150,"column":97}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../js/helpers/ifcond.js")).call(alias1,(depths[2] != null ? lookupProperty(depths[2],"resp_width") : depths[2]),"!=","auto",{"name":"ifcond","hash":{},"fn":container.program(54, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":150,"column":98},"end":{"line":152,"column":25}}})) != null ? stack1 : "")
    + "              data-phase=\""
    + container.escapeExpression(alias2((depths[1] != null ? lookupProperty(depths[1],"category_index") : depths[1]), depth0))
    + "\"\r\n              scope=\"col\" class=\""
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"varname") : depth0), depth0)) != null ? stack1 : "")
    + "\">\r\n              "
    + ((stack1 = alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"0") : stack1), depth0)) != null ? stack1 : "")
    + "\r\n          </th>\r\n";
},"54":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\r\n              style=\"width: "
    + container.escapeExpression(container.lambda((depths[2] != null ? lookupProperty(depths[2],"resp_width") : depths[2]), depth0))
    + ";\"             \r\n";
},"56":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + alias2(__default(require("../js/helpers/set.js")).call(alias1,depth0,{"name":"set","hash":{"response_index":(data && lookupProperty(data,"index"))},"data":data,"loc":{"start":{"line":166,"column":4},"end":{"line":166,"column":38}}}))
    + "\r\n    <tr "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"varname") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":167,"column":8},"end":{"line":167,"column":48}}})) != null ? stack1 : "")
    + ">\r\n      <th class=\"grid-item-text-"
    + alias2(alias3((depths[1] != null ? lookupProperty(depths[1],"cat_text_class") : depths[1]), depth0))
    + "\" scope=\"row\">\r\n        "
    + ((stack1 = alias3((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "\r\n      </th>\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"phases") : depths[1]),{"name":"each","hash":{},"fn":container.program(57, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":171,"column":6},"end":{"line":190,"column":15}}})) != null ? stack1 : "")
    + "    </tr>\r\n";
},"57":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(__default(require("../js/helpers/set.js")).call(alias1,depth0,{"name":"set","hash":{"category_index":(data && lookupProperty(data,"index"))},"data":data,"loc":{"start":{"line":172,"column":6},"end":{"line":172,"column":40}}}))
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,depth0,{"name":"each","hash":{},"fn":container.program(58, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":173,"column":6},"end":{"line":189,"column":15}}})) != null ? stack1 : "");
},"58":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <td "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"varname") : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":174,"column":10},"end":{"line":174,"column":50}}})) != null ? stack1 : "")
    + " class=\"r-"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"varname") : depth0), depth0))
    + " grid-cell\" "
    + ((stack1 = __default(require("../js/helpers/ifnotphase.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"phase_pos") : depths[1]),(depths[1] != null ? lookupProperty(depths[1],"category_index") : depths[1]),{"name":"ifnotphase","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":174,"column":83},"end":{"line":174,"column":166}}})) != null ? stack1 : "")
    + " data-phase=\""
    + alias3(alias2((depths[1] != null ? lookupProperty(depths[1],"category_index") : depths[1]), depth0))
    + "\">\r\n        "
    + ((stack1 = __default(require("../js/helpers/partial.js")).call(alias1,"grid-cell-input",{"name":"partial","hash":{"label_resp":__default(require("../js/helpers/get.js")).call(alias1,(depths[3] != null ? lookupProperty(depths[3],"response_options") : depths[3]),(depths[2] != null ? lookupProperty(depths[2],"response_index") : depths[2]),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":186,"column":21},"end":{"line":186,"column":73}}}),"label_cat":__default(require("../js/helpers/get.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"text") : depth0),0,{"name":"get","hash":{},"data":data,"loc":{"start":{"line":185,"column":20},"end":{"line":185,"column":32}}}),"dk_id":(depths[3] != null ? lookupProperty(depths[3],"dk_id") : depths[3]),"answer":(depth0 != null ? lookupProperty(depth0,"answer") : depth0),"input_class":(depths[1] != null ? lookupProperty(depths[1],"input_class") : depths[1]),"colsum":(depths[3] != null ? lookupProperty(depths[3],"colsum") : depths[3]),"input_width":(depths[3] != null ? lookupProperty(depths[3],"input_width") : depths[3]),"input_type":(depths[3] != null ? lookupProperty(depths[3],"input_type") : depths[3]),"code":(depths[2] != null ? lookupProperty(depths[2],"code") : depths[2]),"category_name":(depth0 != null ? lookupProperty(depth0,"name") : depth0),"name":__default(require("../js/helpers/get.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"response_inputs") : depth0),(depths[2] != null ? lookupProperty(depths[2],"response_index") : depths[2]),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":176,"column":15},"end":{"line":176,"column":57}}})},"data":data,"loc":{"start":{"line":175,"column":8},"end":{"line":187,"column":11}}})) != null ? stack1 : "")
    + "\r\n      </td>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table aria-label=\"Table options\" class=\"grid-layout"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"stripes") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":52},"end":{"line":1,"column":94}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"splitlabels") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":95},"end":{"line":1,"column":144}}})) != null ? stack1 : "")
    + "\" id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-grid\">\r\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"transpose") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(51, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":194,"column":13}}})) != null ? stack1 : "")
    + "\r\n</table>\r\n";
},"useData":true,"useDepths":true});