var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <thead>\n        <tr class=\"matrix-row grid-row-"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"response_options") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">\n            <td>&nbsp;</td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"response_options") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":8,"column":21}}})) != null ? stack1 : "")
    + "        </tr>\n    </thead>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"phases") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":34,"column":13}}})) != null ? stack1 : "")
    + " \n\n    ";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <th class=\"matrix-header-response-"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0)) != null ? stack1 : "")
    + "\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</th>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <tbody id=\"phase-"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\" "
    + ((stack1 = __default(require("../js/helpers/ifnotphase.js")).call(alias3,(depths[1] != null ? lookupProperty(depths[1],"phase_pos") : depths[1]),(data && lookupProperty(data,"index")),{"name":"ifnotphase","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":33},"end":{"line":13,"column":105}}})) != null ? stack1 : "")
    + " data-phase=\""
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,depth0,{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":32,"column":17}}})) != null ? stack1 : "")
    + "  \n    </tbody>\n    ";
},"5":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"varname") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":52}}})) != null ? stack1 : "")
    + ">\n\n            <th class=\"grid-item-text-left\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"response_options") : depths[2]),{"name":"each","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":30,"column":21}}})) != null ? stack1 : "")
    + "        </tr>\n        ";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "id=\"r-"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"varname") : depth0), depth0))
    + "\"";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <td class=\"grid-cell\" >\n                "
    + ((stack1 = __default(require("../js/helpers/partial.js")).call(alias1,"grid-cell-input",{"name":"partial","hash":{"answer":(depths[1] != null ? lookupProperty(depths[1],"answer") : depths[1]),"input_class":(depth0 != null ? lookupProperty(depth0,"input_class") : depth0),"input_width":(depths[2] != null ? lookupProperty(depths[2],"input_width") : depths[2]),"input_type":(depths[3] != null ? lookupProperty(depths[3],"input_type") : depths[3]),"code":(depth0 != null ? lookupProperty(depth0,"code") : depth0),"category_name":(depths[2] != null ? lookupProperty(depths[2],"name") : depths[2]),"name":__default(require("../js/helpers/get.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"response_inputs") : depths[1]),(data && lookupProperty(data,"index")),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":21,"column":23},"end":{"line":21,"column":54}}})},"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":28,"column":19}}})) != null ? stack1 : "")
    + "\n            </td>\n";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " \n\n    <thead>\n        <tr class=\"matrix-row grid-row-"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"category_option") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">\n            <td "
    + ((stack1 = __default(require("../js/helpers/unlessequal.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"resp_width") : depths[1]),"auto",{"name":"unlessequal","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":16},"end":{"line":42,"column":30}}})) != null ? stack1 : "")
    + "            ></td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"phases") : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":12},"end":{"line":53,"column":21}}})) != null ? stack1 : "")
    + "        </tr>\n    </thead>\n    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"response_options") : depths[1]),{"name":"each","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":8},"end":{"line":82,"column":17}}})) != null ? stack1 : "")
    + "    </tbody>\n    ";
},"13":function(container,depth0,helpers,partials,data) {
    return "\n              style=\"width: auto\"\n";
},"15":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + container.escapeExpression(__default(require("../js/helpers/set.js")).call(alias1,depth0,{"name":"set","hash":{"category_index":(data && lookupProperty(data,"index"))},"data":data,"loc":{"start":{"line":45,"column":14},"end":{"line":45,"column":48}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,depth0,{"name":"each","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":14},"end":{"line":52,"column":23}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <th "
    + ((stack1 = __default(require("../js/helpers/ifnotphase.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"phase_pos") : depths[1]),(depths[1] != null ? lookupProperty(depths[1],"category_index") : depths[1]),{"name":"ifnotphase","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":18},"end":{"line":47,"column":101}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../js/helpers/ifcond.js")).call(alias1,(depths[2] != null ? lookupProperty(depths[2],"resp_width") : depths[2]),"!=","auto",{"name":"ifcond","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":102},"end":{"line":49,"column":29}}})) != null ? stack1 : "")
    + "                  data-phase=\""
    + container.escapeExpression(alias2((depths[1] != null ? lookupProperty(depths[1],"category_index") : depths[1]), depth0))
    + "\"\n                  scope=\"col\" class=\""
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"varname") : depth0), depth0)) != null ? stack1 : "")
    + "\">"
    + ((stack1 = alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"0") : stack1), depth0)) != null ? stack1 : "")
    + "</th>\n";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n                  style=\"width: "
    + container.escapeExpression(container.lambda((depths[3] != null ? lookupProperty(depths[3],"resp_width") : depths[3]), depth0))
    + ";\"\n";
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + container.escapeExpression(__default(require("../js/helpers/set.js")).call(alias1,depth0,{"name":"set","hash":{"response_index":(data && lookupProperty(data,"index"))},"data":data,"loc":{"start":{"line":60,"column":8},"end":{"line":60,"column":42}}}))
    + "\n        <tr "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"varname") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":12},"end":{"line":61,"column":52}}})) != null ? stack1 : "")
    + ">\n          <th class=\"grid-item-text-left\" scope=\"row\">\n            "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "\n          </th>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"phases") : depths[1]),{"name":"each","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":10},"end":{"line":80,"column":19}}})) != null ? stack1 : "")
    + "        </tr>\n";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          "
    + container.escapeExpression(__default(require("../js/helpers/set.js")).call(alias1,depth0,{"name":"set","hash":{"category_index":(data && lookupProperty(data,"index"))},"data":data,"loc":{"start":{"line":66,"column":10},"end":{"line":66,"column":44}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,depth0,{"name":"each","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":10},"end":{"line":79,"column":19}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <td "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"varname") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":14},"end":{"line":68,"column":54}}})) != null ? stack1 : "")
    + " class=\"grid-cell\" "
    + ((stack1 = __default(require("../js/helpers/ifnotphase.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"phase_pos") : depths[1]),(depths[1] != null ? lookupProperty(depths[1],"category_index") : depths[1]),{"name":"ifnotphase","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":73},"end":{"line":68,"column":156}}})) != null ? stack1 : "")
    + " data-phase=\""
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"category_index") : depths[1]), depth0))
    + "\">\n            "
    + ((stack1 = __default(require("../js/helpers/partial.js")).call(alias1,"grid-cell-input",{"name":"partial","hash":{"answer":(depth0 != null ? lookupProperty(depth0,"answer") : depth0),"input_class":(depths[2] != null ? lookupProperty(depths[2],"input_class") : depths[2]),"input_width":(depths[4] != null ? lookupProperty(depths[4],"input_width") : depths[4]),"input_type":(depths[4] != null ? lookupProperty(depths[4],"input_type") : depths[4]),"code":(depths[2] != null ? lookupProperty(depths[2],"code") : depths[2]),"category_name":(depth0 != null ? lookupProperty(depth0,"name") : depth0),"name":__default(require("../js/helpers/get.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"response_inputs") : depth0),(depths[2] != null ? lookupProperty(depths[2],"response_index") : depths[2]),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":70,"column":19},"end":{"line":70,"column":61}}})},"data":data,"loc":{"start":{"line":69,"column":12},"end":{"line":77,"column":15}}})) != null ? stack1 : "")
    + "\n          </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-matrix\" class=\"matrix-layout table-striped\" "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"render") : depth0), depth0))
    + "-layout\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"transpose") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(12, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":84,"column":15}}})) != null ? stack1 : "")
    + " \n</table>\n\n<input type=\"hidden\" name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "-row_count\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"cat_count") : depth0), depth0))
    + "\">\n<input type=\"hidden\" name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "-ritem_count\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"response_options") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">";
},"useData":true,"useDepths":true});