var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\"\n  ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label class=\"dk-input\">\n  <input type=\"checkbox\" class=\"dk-check\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"checked") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":42},"end":{"line":3,"column":9}}})) != null ? stack1 : "")
    + ">\n  <span class=\"dk-label\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"dk_text") || (depth0 != null ? lookupProperty(depth0,"dk_text") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"dk_text","hash":{},"data":data,"loc":{"start":{"line":4,"column":25},"end":{"line":4,"column":38}}}) : helper))) != null ? stack1 : "")
    + "</span>\n</label>\n";
},"useData":true});