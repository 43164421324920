/*
 * Widget Templates
 * (Handlebars)
 *
 */
import './handlebars-helpers';
Gryphon = Gryphon || {};
Gryphon.templates = {
  address: require('../widgets/address.hbs'),
  audio: require('../widgets/audio.hbs'),
  calendar: require('../widgets/calendar.hbs'),
  'card-sort': require('../widgets/card-sort.hbs'),
  'cardsort-skinny': require('../widgets/cardsort-skinny.hbs'),
  colorpicker: require('../widgets/colorpicker.hbs'),
  'displaymax-required-message': require('../templates/displaymax-required-message.hbs'),
  dropdown: require('../widgets/dropdown.hbs'),
  'dyngrid-check': require('../widgets/dyngrid-check.hbs'),
  dyngrid: require('../widgets/dyngrid.hbs'),
  'exit-warning': require('../templates/exit-warning.hbs'),
  404: require('../templates/404.hbs'),
  unsupported_browsers: require('../templates/unsupported_browsers.hbs'),
  'facebook-login': require('../widgets/facebook-login.hbs'),
  grid: require('../widgets/grid.hbs'),
  'grid-cell-input': require('../widgets/_grid-cell-input.hbs'),
  'grid-skinny': require('../widgets/grid-skinny.hbs'),
  'grid-wide': require('../widgets/grid-wide.hbs'),
  'image-highlight': require('../widgets/image-highlight.hbs'),
  'imagemap-multiple': require('../widgets/imagemap-multiple.hbs'),
  'imagemap-single': require('../widgets/imagemap-single.hbs'),
  jwplayer: require('../widgets/jwplayer.hbs'),
  matrix: require('../widgets/matrix.hbs'),
  multiple: require('../widgets/multiple.hbs'),
  'multiple-colorpicker': require('../widgets/multiple-colorpicker.hbs'),
  open: require('../widgets/open.hbs'),
  open_entry: require('../widgets/open_entry.hbs'),
  open_inputarea: require('../widgets/open_inputarea.hbs'),
  open_trailer: require('../widgets/open_trailer.hbs'),
  placement: require('../widgets/placement.hbs'),
  popup: require('../widgets/_popup.hbs'),
  rank: require('../widgets/rank.hbs'),
  rank_skinny_dk: require('../widgets/rank_skinny_dk.hbs'),
  rank_skinny_modal: require('../widgets/rank_skinny_modal.hbs'),
  rank_skinny_placement: require('../widgets/rank_skinny_placement.hbs'),
  rank_skinny_selection: require('../widgets/rank_skinny_selection.hbs'),
  rank_viewcontainer: require('../widgets/rank_viewcontainer.hbs'),
  rank_wide_dk: require('../widgets/rank_wide_dk.hbs'),
  rank_wide_placement: require('../widgets/rank_wide_placement.hbs'),
  rank_wide_selection: require('../widgets/rank_wide_selection.hbs'),
  registration: require('../widgets/registration.hbs'),
  'response-button': require('../widgets/_response-button.hbs'),
  'response-item': require('../widgets/_response-item.hbs'),
  rule: require('../widgets/rule.hbs'),
  'rule-partial': require('../widgets/rule-partial.hbs'),
  'dk-partial': require('../widgets/dk-partial.hbs'),
  scale: require('../widgets/scale.hbs'),
  single: require('../widgets/single.hbs'),
  'single-colorpicker': require('../widgets/single-colorpicker.hbs'),
  'text-highlight': require('../widgets/text-highlight.hbs'),
  thermometer: require('../widgets/thermometer.hbs'),
  upload: require('../widgets/upload.hbs'),
  video: require('../widgets/video.hbs'),
  'video-dial': require('../widgets/video-dial.hbs'),
  captcha: require('../widgets/captcha.hbs'),
};
