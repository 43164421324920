var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"rankPlace\" role=\"listitem\">\n  <div class=\"number\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":2,"column":22},"end":{"line":2,"column":31}}}) : helper)))
    + "</div>\n  <div class=\"rank-selections\">\n    <button id=\"rank-dropdown-"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":4,"column":30},"end":{"line":4,"column":39}}}) : helper)))
    + "\" class=\"rank-dropdown\" data-toggle=\"modal\" data-target=\"#rank-modal-"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":4,"column":108},"end":{"line":4,"column":117}}}) : helper)))
    + "\">\n    </button>\n    <div class=\"modal fade rank-dropdown-modal\" id=\"rank-modal-"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":6,"column":63},"end":{"line":6,"column":72}}}) : helper)))
    + "\" tabindex=\"-1\" role=\"dialog\">\n      <div class=\"modal-dialog\" role=\"document\">\n        <div class=\"modal-content\">\n          <div class=\"modal-header\">\n            <div class=\"modal-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"small_selection_placeholder") || (depth0 != null ? lookupProperty(depth0,"small_selection_placeholder") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"small_selection_placeholder","hash":{},"data":data,"loc":{"start":{"line":10,"column":37},"end":{"line":10,"column":68}}}) : helper)))
    + "</div>\n            <div class=\"rank-modal-close\" data-dismiss=\"modal\"><span>&times;</span></div>\n          </div>\n          <div class=\"modal-body\">\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});