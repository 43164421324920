var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"rankSelect\" id=\"source-slot-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":1,"column":40},"end":{"line":1,"column":48}}}) : helper)))
    + "\" role=\"listitem\">\n  <div class=\"select\">\n    <div class=\"icon add accessible-dropdown-trigger\">\n      <button\n        class=\"material-icons\"\n        aria-haspopup=\"true\"\n        role=\"button\"\n        tabindex=\"0\"\n      >\n        <i class=\"drag-drop-icon\" aria-hidden=\"true\">\n            <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 20 20\" style=\"enable-background:new 0 0 20 20\" xml:space=\"preserve\">\n              <path d=\"M9 2H6v3h3V2zm6 0h-3v3h3V2zM9 8H6v3h3V8zm6 0h-3v3h3V8zm-9 9h3v-3H6v3zm6 0h3v-3h-3v3z\" style=\"fill:#33415c\"/>\n            </svg>\n        </i>\n        <span class=\"assistive-text\">Move Item</span>\n      </button>\n      <ul class=\"accessible-dropdown\" role=\"menu\"></ul>\n    </div>\n    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":19,"column":14}}}) : helper))) != null ? stack1 : "")
    + "\n  </div>\n</div>\n";
},"useData":true});