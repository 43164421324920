import '../../../vendor/video-dial/lib/video.modified.js';
import '../../../vendor/video-dial/requirement/html-canvas-video-player/js/canvas-video-player.js';
import '../../../vendor/video-dial/lib/video-dial.js';
(function ($, widgets) {
  var VideoDial = widgets.Widget.extend(
    {
      constructor: function VideoDialConstructor(object) {
        VideoDial.__super__.constructor.call(this, object);
        this._results = {
          dial: [],
          marker: [],
        };

        this._lastDialValue = null;

        this._dialOptions = {
          allowFullscreen: object.allow_fullscreen,
          allowPause: object.allow_pause,
          autoFullscreen: object.auto_fullscreen,
          autoPlay: object.auto_play,
          dialchange: object.hide_dial
            ? $.noop
            : $.proxy(this, '_onDialChange'),
          dialmarker: $.proxy(this, '_onDialMarker'),
          dial: {
            hide: object.hide_dial,
            minText: object.left_text,
            maxText: object.right_text,
            minColor: object.left_color,
            maxColor: object.right_color,
          },
          pauseText: object.pause_text,
          playText: object.play_text,
          plays: object.plays,
          element_id: object.element_id,
        };

        if (object.marker_text) {
          $.extend(this._dialOptions, {
            marker: {
              text: object.marker_text,
              maxCount: object.marker_count,
              disabledText: object.marker_disabled_text,
              color: object.marker_color || false,
            },
          });
        }
      },

      render: function render() {
        $.when(
          Gryphon.util.import_css('/static/video/video-js.css')
          // Gryphon.util.getScript(page_state.static_prefix + '/js/video.js')
        ).then($.proxy(this, '_render'));
      },

      destroy: function destroy() {
        VideoDial.__super__.destroy.call(this);
        this._logEvent({
          event: 'nextQuestion',
          message: 'Next question',
        });
        // clear any duration / player timer
        if (this.timer) {
          clearInterval(this.timer);
        }
        if (this.player && typeof this.player.dispose === 'function') {
          this.player.dispose();
        }
      },

      _render: function () {
        VideoDial.__super__.render.call(this);
        VideoDial._object = this._object;
        this.dialInput = this.$el.find('#r-' + this._object.input_id);
        this.markerInput = this.$el.find('#r-' + this._object.marker_input);
        var object = this._object;
        var autoplay = object.auto_play;
        var plays = object.plays;
        var total_plays = 0;
        var watchedVal = page_state.form_data[object.status_input] || '0';
        var watchedInput = $('input[name="' + object.status_input + '"]');
        var timer;
        var self = this;
        var iOS =
          /iPad|iPhone|iPod/.test(navigator.platform) ||
          (/iPad|iPhone|iPod/.test(navigator.userAgent) &&
            !/Windows/.test(navigator.userAgent));

        watchedInput.val(watchedVal);

        videojs.options.flash.swf = '/static/video/video-js.swf';
        this._has_flash = Gryphon.util.has_flash();

        // Note: To be used with iOS / autoplay
        // Show the mute button so users may
        // unmute the playing video
        // Ref https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
        function toggleMuteButton() {
          var checkVolumeCtrl, checkMuteCtrl;
          $('.vjs-control-bar').show().css({
            'background-color': 'transparent',
            visibility: 'visible',
            opacity: 1,
          });
          setTimeout(function () {
            $('.vjs-control').not('.vjs-mute-control').addClass('vjs-hidden');
            checkVolumeCtrl = setInterval(function () {
              if ($('.vjs-volume-control').is(':visible')) {
                clearInterval(checkVolumeCtrl);
                $('.vjs-volume-control').addClass('vjs-hidden');
              }
            }, 100);
            if (!$('.vjs-mute-control').hasClass('vjs-vol-0')) {
              $('.vjs-mute-control')
                .addClass('vjs-vol-0')
                .css({
                  'font-size': '14px',
                  bottom: '12px',
                  right: '-10px',
                })
                .removeClass('vjs-hidden');
            }
            checkMuteCtrl = setInterval(function () {
              if ($('.vjs-mute-control').hasClass('vjs-hidden')) {
                $('.vjs-mute-control').removeClass('vjs-hidden');
                clearInterval(checkMuteCtrl);
              }
            }, 100);
          });
        }

        function initializePlayer(player) {
          self._logEvent({ event: 'initializePlayer' });
          if (iOS && autoplay) {
            toggleMuteButton();
          }
          if (
            !window.page_state.hasOwnProperty('preview_data') &&
            localStorage &&
            plays > 0 &&
            localStorage.getItem(object.visa + '_plays_' + object.element_id) >=
              plays
          ) {
            // Set the status, marker and response inputs based on saved localStorage data
            var status = localStorage.getItem(
              object.visa + '_status_' + object.element_id
            );
            $('#r-' + self._object.status_input).val(status);
            var response = localStorage.getItem(
              object.visa + '_response_' + object.element_id
            );
            $('#r-' + self._object.input_id).val(response);
            var marker = localStorage.getItem(
              object.visa + '_marker_' + object.element_id
            );
            $('#r-' + self._object.marker_input).val(marker);

            // Condition :: The limit on the number of times this video can be viewed has been reached,
            // and we're not in a 'preview' context
            $('.question-video-dial').prepend(
              '<div class="alert alert-error">' +
                'The limit on the number of times this video can be viewed ' +
                'has been reached. Please proceed to the next question.' +
                '</div>'
            );

            // Disable pointer/touch events on video player element
            $('#videoplayer').css({ 'pointer-events': 'none' });
            // Allow the user to progress through this question
            mainNav.show();
          }

          var supposedCurrentTime = 0;

          player.on('timeupdate', function () {
            if (!player.seeking()) {
              supposedCurrentTime = player.currentTime();
            }
          });

          player.on('seeking', function () {
            var delta = player.currentTime() - supposedCurrentTime;
            if (Math.abs(delta) > 0.01) {
              player.currentTime(supposedCurrentTime);
            }
          });

          player.on('error', function (event) {
            if (event.target.error) {
              switch (event.target.error.code) {
                case 1:
                  self._logEvent({
                    event: 'error',
                    message:
                      '[MEDIA_ERR_ABORTED] Fetching of the resource was aborted',
                    widget: 'video-dial',
                  });
                  break;
                case 2:
                  self._logEvent({
                    event: 'error',
                    message:
                      '[MEDIA_ERR_NETWORK] Network error occurred which prevented the ' +
                      'media from being successfully fetched',
                    widget: 'video-dial',
                  });
                  break;
                case 3:
                  self._logEvent({
                    event: 'error',
                    message:
                      '[MEDIA_ERR_DECODE] An error occurred while trying to decode ' +
                      'the media resource',
                    widget: 'video-dial',
                  });
                  break;
                case 4:
                  self._logEvent({
                    event: 'error',
                    message:
                      '[MEDIA_ERR_SRC_NOT_SUPPORTED] The associated resource has been ' +
                      'found to be unsuitable',
                    widget: 'video-dial',
                  });

                  // Fallback to next source
                  var sources = object.player.sources.filter(
                    (source) => source.src !== player.currentSrc()
                  );
                  if (sources.length > 0) {
                    player.src(sources);
                    return;
                  } else {
                    self._logEvent({
                      event: 'error',
                      message: 'No supported media sources found',
                      widget: 'video-dial',
                    });
                  }
                  break;
                default:
                  self._logEvent({
                    event: 'error',
                    message: 'Unknown',
                    widget: 'video-dial',
                  });
              }
            } else {
              self._logEvent({
                event: 'error',
                message: 'Unknown',
                widget: 'video-dial',
              });
            }

            self._setStatus(self._object.response_codes.ERROR);
            mainNav.show();
            setTimeout(function () {
              $('#next_button').click();
            }, 300);
          });

          var onEnded = function () {
            // NOTE: https://bugs.chromium.org/p/chromium/issues/detail?id=157543
            supposedCurrentTime = 0;

            self._setStatus(self._object.response_codes.WATCHED);

            total_plays++;
            self._logEvent({
              event: 'ended',
              widget: 'video-dial',
              totalPlays: total_plays,
            });
            self._dialOptions.totalPlays = total_plays;

            if (
              !window.page_state.hasOwnProperty('preview_data') &&
              localStorage &&
              localStorage.setItem
            ) {
              localStorage.setItem(
                object.visa + '_plays_' + object.element_id,
                total_plays
              );
              localStorage.setItem(
                object.visa + '_status_' + object.element_id,
                self._object.response_codes.WATCHED
              );
              localStorage.setItem(
                object.visa + '_response_' + object.element_id,
                $('#r-' + self._object.input_id).val()
              );
              localStorage.setItem(
                object.visa + '_marker_' + object.element_id,
                $('#r-' + self._object.marker_input).val()
              );
            }

            if (plays > 0 && total_plays >= plays) {
              setTimeout(function () {
                $('#next_button').click();
              }, 300);
              return;
            }

            try {
              player.currentTime(0);

              $('#touchPlayBtn .fa')
                .removeClass('fa-pause')
                .addClass('fa-play');
              $('#touchPlayBtn').fadeIn('fast');

              // [GRYP-9969] If fullscreen allowed, show icon on video ending
              if (self._dialOptions.allowFullscreen) {
                $('.btn-dial-fullwindow').fadeIn('fast');
              }

              $('.ui-slider-handle')
                .addClass('paused')
                .removeClass('playing')
                .show();
              $(document).off('mousemove.video-dial');
              $('#video-dial .marker').show();
            } catch (e) {}

            mainNav.show();
          };

          player.on('ended', onEnded);

          player.on('play', function () {
            self._logEvent({ event: 'play', widget: 'video-dial' });
          });

          player.on('pause', function () {
            if (player.currentTime() !== player.duration()) {
              self._logEvent({
                event: 'pause',
                widget: 'video-dial',
              });
            }
          });

          player.on('', function () {
            self._logEvent({ event: 'no event' });
          });

          var otherEvents = [
            'loadstart',
            'loadedmetadata',
            'loadeddata',
            'loadedalldata',
          ];

          $.each(otherEvents, function (i, eventName) {
            var logEvent = $.proxy(self, '_logEvent', {
              event: eventName,
            });
            player.on(eventName, logEvent);
          });

          // If video isn't playable
          if (player.currentSrc() === '') {
            player.trigger({ type: 'error', message: 'No source' });
          }
        }

        self._logEvent({
          event: 'Video Dial Template loaded',
          videoSettings: object.player,
        });

        if (iOS && autoplay) {
          // By default, iOS devices must have the video muted in order to autoplay
          $('#player').attr('muted', 'muted');
        }
        var player = (this.player = videojs('player', {
          preload: 'auto',
          'webkit-playsinline': true,
          playsinline: true,
          techOrder: ['html5', 'flash'],
        }));

        player.ready($.proxy(player, 'videoDial', this._dialOptions));
        player.ready(function () {
          player._isReady = true;
          self._logEvent({ event: 'playerReady' });
          // Wait until the player has loaded before attaching event listeners
          initializePlayer(player);
        });
      },

      _logEvent: function _logEvent(data) {
        /* jshint devel: true */
        if (!window.eventLog) {
          return;
        }

        var player = this.player;

        try {
          data = data || {};

          data.application = 'gryphon.ivw';
          data.date = Date();
          data.userAgent = navigator.userAgent;
          data.questionType = 'video-dial';
          data.playerIsReady = false;
          data.url = window.location.href;
          data.has_flash = this._has_flash;

          if (player && player._isReady) {
            data.playerIsReady = true;
            data.currentSrc = player.currentSrc();
            data.currentTime = player.currentTime();
            data.duration = player.duration();
            data.tech = player.techName.toLowerCase();
          }

          eventLog.log(data);
        } catch (err) {
          if (window.console) {
            window.console.log(err);
          }
        }
      },

      _onDialChange: function _onDialChange(value, timestamp) {
        if (
          !value ||
          typeof value === 'object' ||
          value === this._lastDialValue
        ) {
          return;
        }
        this._results.dial.push(
          timestamp.toFixed(VideoDial._timestampPrecision) + ':' + value
        );
        this.dialInput.val(this._results.dial.join(','));
        this._lastDialValue = value;
      },

      _onDialMarker: function _onDialMarker(timestamp) {
        this._results.marker.push(
          timestamp.toFixed(VideoDial._timestampPrecision)
        );
        this.markerInput.val(this._results.marker.join(','));
      },

      _setStatus: function _setStatus(code) {
        var self = this;
        $('#r-' + this._object.status_input).val(code);
        try {
          if (!this._lastDialValue || typeof this._lastDialValue === 'object') {
            this._lastDialValue = 50;
          }
          var fVal =
            this.player.currentTime().toFixed(VideoDial._timestampPrecision) +
            ':' +
            this._lastDialValue;
          if (this._results.dial && this._results.dial.indexOf(fVal) == -1) {
            this._results.dial.push(fVal);
            this.dialInput.val(this._results.dial.join(','));
          }
        } catch (e) {
          self._logEvent({
            event: 'error',
            message: e || 'Unknown error while setting video dial status',
          });
        }
      },
    },
    {
      types: ['video-dial'],
      views: ['video-dial'],
      _timestampPrecision: 2,
    }
  );

  VideoDial.register();
  widgets.VideoDial = VideoDial;
})(jQuery, (Gryphon.widgets = Gryphon.widgets || {}));
