var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div role=\"note\" class=\"sr-only\" tabindex=\"0\">\n    Previous answer is \n"
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answer") : depth0),997,{"name":"ifequal","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":10,"column":16}}})) != null ? stack1 : "")
    + "  </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(container.lambda((depths[1] != null ? lookupProperty(depths[1],"dk_text") : depths[1]), depth0))
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + container.escapeExpression(__default(require("../js/helpers/ans.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answer") : depth0),(depth0 != null ? lookupProperty(depth0,"response_id") : depth0),{"name":"ans","hash":{},"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":9,"column":32}}}))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./error.hbs"),depth0,{"name":"error","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = __default(require("../js/helpers/partial.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"dk-partial",{"name":"partial","hash":{"dk_id":(depth0 != null ? lookupProperty(depth0,"dk_id") : depth0),"wide_view":(depth0 != null ? lookupProperty(depth0,"wide_view") : depth0),"narrow_view":(depth0 != null ? lookupProperty(depth0,"narrow_view") : depth0),"dk_value":(depth0 != null ? lookupProperty(depth0,"dk_value") : depth0),"response_id":(depth0 != null ? lookupProperty(depth0,"response_id") : depth0),"dk_text":(depth0 != null ? lookupProperty(depth0,"dk_text") : depth0)},"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":38,"column":37}}})) != null ? stack1 : "")
    + " ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"question question-rule\">\n  <legend data-testid=\"rule-question-text\" class=\"question-text\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-label\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</legend>\n"
    + ((stack1 = __default(require("../js/helpers/ifanswerexists.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"response_id") : depth0),{"name":"ifanswerexists","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":12,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"error_message") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":2},"end":{"line":15,"column":9}}})) != null ? stack1 : "")
    + "  <script>\n    window.rule_narrow_view = '"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"narrow_view") : depth0), depth0))
    + "'\n    window.rule_wide_view = '"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"wide_view") : depth0), depth0))
    + "'\n  </script>\n  <div aria-live=\"polite\"\n    class=\"placement-widget rule-outer narrow_view_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"narrow_view") : depth0), depth0))
    + " wide_view_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"wide_view") : depth0), depth0))
    + " clearfix\"\n  >\n    "
    + ((stack1 = __default(require("../js/helpers/partial.js")).call(alias3,"rule-partial",{"name":"partial","hash":{"show_value":(depth0 != null ? lookupProperty(depth0,"show_value") : depth0),"input_id":(depth0 != null ? lookupProperty(depth0,"input_id") : depth0),"right_message":(depth0 != null ? lookupProperty(depth0,"right_message") : depth0),"left_message":(depth0 != null ? lookupProperty(depth0,"left_message") : depth0),"increment":(depth0 != null ? lookupProperty(depth0,"increment") : depth0),"max":(depth0 != null ? lookupProperty(depth0,"max") : depth0),"min":(depth0 != null ? lookupProperty(depth0,"min") : depth0),"widget":"rule"},"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":25,"column":47}}})) != null ? stack1 : "")
    + "\n    <input\n      type=\"hidden\"\n      name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"response_id") : depth0), depth0))
    + "\"\n      id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-value\"\n      class=\"hidden-input\"\n      data-testid=\"rule-value-element\"\n      value=\""
    + alias2(__default(require("../js/helpers/ans.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"answer") : depth0),(depth0 != null ? lookupProperty(depth0,"response_id") : depth0),{"name":"ans","hash":{},"data":data,"loc":{"start":{"line":32,"column":13},"end":{"line":32,"column":39}}}))
    + "\"\n    />\n  </div>\n  <span aria-live=\"polite\">\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"dont_know") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":2},"end":{"line":38,"column":45}}})) != null ? stack1 : "")
    + "\n  </span>\n</fieldset>\n";
},"usePartial":true,"useData":true,"useDepths":true});