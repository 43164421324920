(function ($) {
  function debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  }

  var _placement = {
    is_vertical: function () {
      return (
        (window.placement_narrow_view == 'vertical' &&
          $(window).width() <= 640) ||
        window.placement_wide_view == 'vertical'
      );
    },

    get_position: function (e) {
      var pos = $(e).position();
      var offset = $(e).offset();
      return {
        el: e,
        top: pos.top,
        left: pos.left,
        abs_top: offset.top,
        abs_left: offset.left,
        abs_right: offset.left + $(e).width(),
        abs_bottom: offset.top + $(e).height(),
      };
    },

    place_pointer_text: function (slider, pointer) {
      setTimeout(function () {
        if (!$(slider).plcslider('is_slider')) {
          return;
        }
        var pointers_txt = $('.placement-pointer-text.dragged');
        var pointer_txt = pointer.find(
          '.placement-pointer-text.dragged[data-collision=false]'
        );
        pointer_txt.removeClass('isColliding');
        if (_placement.is_vertical()) {
          pointers_txt.css('top', 0);
        } else {
          pointer_txt.css('margin-top', 0);
        }
        _placement.move_pointer_text(pointers_txt);
      });
    },

    vert_intersect: function (stationary, movable) {
      var rect, pointer, top, bottom;
      if (stationary.length !== undefined && stationary.length > 0) {
        for (var i = 0; i < stationary.length; i++) {
          rect = _placement.get_position(stationary);
          pointer = _placement.get_position(movable);
          top =
            rect.abs_top <= pointer.abs_top &&
            pointer.abs_top <= rect.abs_bottom;
          bottom =
            rect.abs_top <= pointer.abs_bottom &&
            pointer.abs_bottom <= rect.abs_bottom;
          return top || bottom;
        }
      } else {
        rect = _placement.get_position(stationary);
        pointer = _placement.get_position(movable);
        top =
          rect.abs_top <= pointer.abs_top && pointer.abs_top <= rect.abs_bottom;
        bottom =
          rect.abs_top <= pointer.abs_bottom &&
          pointer.abs_bottom <= rect.abs_bottom;
        return top || bottom;
      }
    },

    horz_intersect: function (stationary, movable) {
      var rect = _placement.get_position(stationary);
      var pointer = _placement.get_position(movable);

      var left =
        rect.abs_left <= pointer.abs_left && pointer.abs_left <= rect.abs_right;

      var right =
        rect.abs_left <= pointer.abs_right &&
        pointer.abs_right <= rect.abs_right;

      return left || right;
    },

    isCollide: function (a, b) {
      if (a === b) {
        return false;
      }
      return !(
        a.offset().top + a.outerHeight() < b.offset().top ||
        a.offset().top > b.offset().top + b.outerHeight() ||
        a.offset().left + a.outerWidth() < b.offset().left ||
        a.offset().left > b.offset().left + b.outerWidth()
      );
    },

    intersect: function (container, point) {
      var v = _placement.vert_intersect(container, point);
      var h = _placement.horz_intersect(container, point);
      return v && h;
    },

    rectIntersect: function (x1, y1, w1, h1, x2, y2, w2, h2) {
      // Check x and y for overlap
      if (x2 > w1 + x1 || x1 > w2 + x2 || y2 > h1 + y1 || y1 > h2 + y2) {
        return false;
      }
      return true;
    },

    detectCollisions: function () {
      // collision detection for placement boxes
      let obj1;
      let obj2;
      let placedItems = $('.placement-pointer-text.dragged');

      // Reset collision state of all objects
      for (let i = 0; i < placedItems.length; i++) {
        $(placedItems[i]).attr('data-collision', false);
      }

      // Start checking for collisions
      for (let i = 0; i < placedItems.length; i++) {
        obj1 = $(placedItems[i]);

        for (let j = i + 1; j < placedItems.length; j++) {
          obj2 = $(placedItems[j]);

          // Compare object1 with object2
          if (
            _placement.rectIntersect(
              obj1.offset().left,
              obj1.offset().top,
              obj1.outerWidth(),
              obj1.outerHeight(),
              obj2.offset().left,
              obj2.offset().top,
              obj2.outerWidth(),
              obj2.outerHeight()
            )
          ) {
            obj1.attr('data-collision', true);
            obj2.attr('data-collision', true);

            // Return a list of elements with collision=true as data-attribute
            return $('.placement-pointer-text.dragged[data-collision=true]');
          }
        }
      }

      return [];
    },

    handleCollisions: function (collisions) {
      var top = function (e) {
        return parseInt($(e).css('margin-top').replace('px', ''));
      };

      var bottom = function (e) {
        return parseInt($(e).parent().css('bottom').replace('px', ''));
      };

      var offsetTop = function (e) {
        return $(e).parent().offset().top;
      };

      var positionTop = function (e) {
        return parseInt($(e).css('top').replace('px', ''));
      };

      _.each(collisions, function (collision, idx) {
        if (!$(collision).hasClass('isColliding')) {
          $(collision).addClass('isColliding');
        }

        if (idx > 0) {
          offset =
            bottom(collisions[idx - 1]) - bottom(collision) > 0
              ? bottom(collisions[idx - 1]) - bottom(collision)
              : 0;

          // Start Vertical Handling
          // since we're using relative positioning and top instead of margin-top
          // which gives better control within the collision detection
          if (_placement.is_vertical()) {
            var collisionCurrentTop =
              positionTop(collisions[idx - 1]) > 0
                ? positionTop(collisions[idx - 1])
                : 0;
            var collisionPrevTop =
              positionTop(collision) > 0 ? positionTop(collision) : 0;

            offset =
              offsetTop(collision) - offsetTop(collisions[idx - 1]) > 0
                ? offsetTop(collision) - offsetTop(collisions[idx - 1])
                : 0;

            // Save for use in collision recursions
            if (offset > 0) {
              $(collision).data('offset', offset);
            }

            // Take top offset of current or previous collision, add the height of the item, and an 11 pixel spacing (per designs)
            var position =
              collisionCurrentTop + $(collision).height() + 11 - offset;
            if (position === collisionPrevTop) {
              position =
                position +
                (position - collisionCurrentTop - collisionCurrentTop);
              if ($(collision).data('offset')) {
                position = position - $(collision).data('offset');
              }
            }

            $(collision).css({
              position: 'relative',
              top: position + 'px',
            });
          }
          // End Vertical Handling

          // Start Horizontal Handling
          m =
            !_placement.is_vertical() &&
            top(collisions[idx - 1]) +
              ($(collisions[idx - 1]).height() + 11 - offset) >
              0
              ? top(collisions[idx - 1]) +
                ($(collisions[idx - 1]).height() + 11 - offset)
              : 0;
          // End Horizontal Handling

          $(collision)
            .css({ 'margin-top': m + 'px' })
            .closest('.placement-indicator')
            .css({ 'z-index': m > 0 ? Math.round(1000 - m / 10) : 1000 - idx });
        }
      });
    },

    move_pointer_text: function (pointers) {
      var wrapper = $('.placement-wrapper');
      var pointers_txt = $('.placement-pointer-text.dragged');
      var offset;

      var top = function (e) {
        return parseInt($(e).css('margin-top').replace('px', ''));
      };
      var count = 0;

      var reset_pointers = function (ptrs) {
        count++;
        if (count > $('.placement-pointer-text').length * 3) {
          return;
        }
        var domCollisions, m;

        if (ptrs.length > 1) {
          ptrs.sort(function (a, b) {
            if (_placement.is_vertical()) {
              $(a).removeClass('isColliding');
              $(b).removeClass('isColliding');
              return $(a).offset().top - $(b).offset().top;
            }
          });

          domCollisions = _placement.detectCollisions();

          if (domCollisions.length > 0) {
            if (_placement.is_vertical()) {
              domCollisions.sort(function (a, b) {
                return $(a).offset().top - $(b).offset().top;
              });
            }

            // Abstracted into a separate function
            _placement.handleCollisions(domCollisions);

            if (_placement.detectCollisions().length > 0) {
              // recursively calculate any new collisions
              reset_pointers(ptrs);
            }
          } else {
            // Ensure no outstanding collisions
            if (_placement.detectCollisions().length > 0) {
              // recursively calculate any new collisions
              reset_pointers(ptrs);
            }
          }
        } else {
          if ($('#placement-notsure').length === 0) {
            $('.content-container > form').css('margin-bottom', 24);
          }
        }

        if (_placement.detectCollisions().length > 0) {
          // recursively calculate any new collisions
          reset_pointers(ptrs);
        }

        // [GRYP-10379] If the pointers contain an image, set a
        // timeout to allow for redraws of the image
        const ptrsContainImage = () =>
          ptrs
            .toArray()
            .some((el) => $(el).find('.placement-label:has(img)').length > 0);
        setTimeout(
          function () {
            wrapper.css({ height: wrapper[0].scrollHeight + 'px' });
          },
          ptrsContainImage() ? 100 : 0
        );
      };

      var move = function (ptrs) {
        reset_pointers(ptrs);
        var left = ptrs[0];
        var reset = false;
        for (var i = 1; i < ptrs.length; i++) {
          var point = ptrs[i];
          if (!_placement.is_vertical()) {
            if (!_placement.horz_intersect(left, point)) {
              reset = ptrs.slice(i);
              break;
            }
          }
        }
        if (reset && reset.length > 1) {
          move(reset);
        }
      };

      if (_placement.is_vertical()) {
        reset_pointers(pointers_txt);
      } else {
        move(pointers_txt);
      }
    },

    init_sliders: function (info) {
      // Use the currently visible slider for placements
      var slider = $(info.slider_id + ':visible');
      var moved = false;
      var point_id = '';
      var values = [];
      var sz = info.options.value_max
        ? parseInt(info.options.value_max).toString().length
        : 3;
      if (sz < 3) {
        sz = 3;
      } // minimum 3 digits for missing values
      var dk_value = Math.pow(10, sz) - 3; // jshint ignore:line
      var skipped_value = Math.pow(10, sz) - 2; // jshint ignore:line
      $.each($('.placement-values'), function (i, n) {
        var id = $(n)
          .attr('id')
          .substring(0, $(n).attr('id').indexOf('-value'));
        var value = $(n).val();
        if (
          !value ||
          parseInt(value) == skipped_value ||
          value == '__DK__' ||
          parseInt(value) === dk_value
        ) {
          // Don't Know
          if (value == '__DK__' || parseInt(value) === dk_value) {
            $('#' + id + '-item').appendTo($('#placement-notsure'));
          }
        } else {
          moved = $('#' + id);
          point_id = '#' + id + '-item';
          values.push(value);
          var point = {
            point: $(point_id),
            value: value,
          };
          $(slider).plcslider('add', point);
          $(point_id).css('margin-top', 0);
        }
      });
      if (moved != false) {
        // only need to move one to make all the label positions reset
        _placement.place_pointer_text(slider, moved);
      }
    },

    init_placement_options: function (info) {
      $('.placement-options').droppable({
        accept: '.ui-draggable',
        hoverClass: 'hover-drop',
        drop: function (event, ui, source) {
          var drag = source;
          if (!source) drag = ui.draggable;
          var drag_id = $(drag).attr('id').replace('-item', '');
          var key = '#' + drag_id + '-value';
          setTimeout(function () {
            $(key).val(null);
          });

          drag
            .removeClass('placement-indicator')
            .addClass('placement-item')
            .attr('style', 'position: relative');

          var div = drag.find('div');
          div.removeClass('placement-pointer-text');

          $('.placement-options').append(drag);

          $(info.drag_cls).draggable({
            revert: 'invalid',
            zIndex: 1000,
            scroll: false,
            drag: function (event, ui) {
              // horizontal layout
              if (!_placement.is_vertical()) {
                var snapBot =
                  Math.abs(
                    window.slider.offset.bottom -
                      (ui.offset.top + window.slider.height)
                  ) <= 10;

                if (snapBot) {
                  ui.position.top =
                    $(ui.helper).parent().attr('id') === 'placement-notsure'
                      ? -99
                      : window.slider.offset.top -
                        $('.placement-options').offset().top -
                        window.slider.height / 2;
                  return;
                }
              }
              // vertical layout
              if (_placement.is_vertical()) {
                var snapLeft =
                  Math.abs(window.slider.offset.left - ui.offset.left) <= 10;

                if (snapLeft) {
                  if ($(window).width() <= 640) {
                    ui.position.left = window.slider.offset.left;
                  }
                  return;
                }
              }
            },
          });
        },
      });
    },

    init_notsure: function (info) {
      try {
        window.dk_margin_top = parseInt(
          $('.placement-notsure-wrapper').css('margin-top').replace('px', '')
        );
      } catch (e) {}
      $('#placement-notsure').droppable({
        accept: '.ui-draggable',
        hoverClass: 'placement-accepts-hover',

        drop: function (event, ui, source) {
          var drag = source;
          if (!source) {
            drag = ui.draggable;
            $(drag).draggable('option', 'revert', false);
          }
          var drag_id = $(drag)
            .attr('id')
            .substring(0, $(drag).attr('id').indexOf('-item'));
          var key = '#' + drag_id + '-value';
          $(key).val('__DK__');

          if (drag.hasClass('placement-indicator')) {
            var span = drag.find('span');
            var div = $('<div class="placement-pointer-text"></div>')
              .addClass(span.attr('class'))
              .attr('style', span.attr('style'))
              .html(span.html());

            drag
              .removeClass('placement-indicator')
              .addClass('placement-item')
              .attr('style', 'position: relative');
            drag.find('.dragged').show();

            span = $('<span></span>')
              .addClass(div.attr('class'))
              .text(div.text());

            div.replaceWith(span);
          }

          $('#placement-notsure').append(drag);
          $(drag).css({ top: 0, left: 0 });

          // Check whether the pointer was dragged outside
          // the not sure space and place either back in the
          // available pointer list or place it on the
          // scale.
          var notsure_drag = function (event, ui) {
            if ($(this).parent().attr('id') === 'placement-notsure') {
              var x = _placement.intersect;
              if (
                !x($('#placement-notsure'), $(this)) &&
                !x($(info.slider_id + ':visible'), $(this))
              ) {
                $('.placement-options').append($(this));
                $(this).css({ top: 0, left: 0 });
              }
            } else {
              if (
                ui.offset.top !== ui.position.top &&
                _placement.is_vertical() &&
                ui.position.top < 0
              ) {
                $(ui.helper).css(
                  'top',
                  ui.offset.top -
                    window._containment.north +
                    $(ui.helper).height() / 3
                );
                var pointer_id =
                  '#' +
                  $(ui.helper)
                    .attr('id')
                    .replace(/(item)/, 'value');
                var value = $('.placement-slider:visible').plcslider('value', {
                  point: ui.helper,
                });
                $(pointer_id).val(value);
              }
            }
          };
          $(drag).draggable('option', 'stop', notsure_drag);

          $(info.drag_cls).draggable({
            revert: 'invalid',
            zIndex: 2,
            scroll: false,
            drag: function (event, ui) {
              // horizontal layout
              if (!_placement.is_vertical()) {
                var snapBot =
                  Math.abs(
                    window.slider.offset.bottom -
                      (ui.offset.top + window.slider.height)
                  ) <= 10;

                if (snapBot) {
                  ui.position.top =
                    $(ui.helper).parent().attr('id') === 'placement-notsure'
                      ? -99
                      : window.slider.offset.top -
                        $('.placement-options').offset().top -
                        window.slider.height / 2;
                  return;
                }
              }
            },
          });
          _placement.init_progress(info);
        },
      });
    },

    check_progress: function (info) {
      var pointers_left = $.grep($(info.data_cls), function (n, i) {
        var value = $(n).val();
        if (!value || value == 998) {
          return true;
        }
        return false;
      });
      return pointers_left;
    },

    init_progress: function (info) {
      var button_states = {
        back_button: $('#back_button').css('display') !== 'none', // Case 59180
        next_button: true,
        hide_nav: false,
      };
      if ($('#required').length > 0) {
        var pointers = _placement.check_progress(info);
        if (pointers.length != 0 && $('#required').text() === 'HARD') {
          button_states.next_button = false;
        }
      }
      set_nav_button_visibility(button_states);
    },

    init_plcslider: function (info) {
      $(info.slider_id + ':visible').plcslider({
        points: info.drag_cls,
        pointers: info.pointer_cls,
        handle: '.point',
        top: !_placement.is_vertical() ? 40 : 0,
        zIndex: 1000,
        options: info.options,

        // set the form value and adjust pointer text
        stop: _placement.stop_slider,

        // function to replace content with pointer image
        drop: _placement.drop_slider,
      });
    },

    drop_slider: function (event, ui, source) {
      var drag = source;
      if (!source) drag = ui.draggable;
      var span = drag.find('span');
      var div = $('<span class="placement-pointer-text dragged"></span>')
        .addClass(span.attr('class'))
        .attr('style', span.attr('style'))
        .html(span.html());

      drag.find('span').replaceWith(div);

      var marginTop = 0;
      if (
        $('.placement-indicator:first').find('.placement-pointer-text.dragged')
          .length > 0
      ) {
        marginTop = parseInt(
          $('.placement-indicator:first')
            .find('.placement-pointer-text.dragged')
            .css('margin-top')
            .replace('px', '')
        );
      }
      $('.placement-indicator:first').css(
        'z-index',
        marginTop > 0 ? Math.round(1000 - marginTop / 10) : 1000
      );
    },

    stop_slider: function (event, ui, source, forcedValue) {
      var drag = source;
      var handle;
      if (!source) {
        drag = ui.draggable;
        handle = ui.handle;
      }
      var pointer_id =
        '#' +
        $(drag)
          .attr('id')
          .replace(/(item)/, 'value');
      var sliderParent =
        $('.responsive-grid-skinny').css('display') === 'none'
          ? '.responsive-grid-wide'
          : '.responsive-grid-skinny';

      var value = $(
        `${sliderParent} ${_placement.info.slider_id}:visible`
      ).plcslider('value', {
        point: drag,
      });

      if (forcedValue) value = forcedValue;
      $(pointer_id).attr('value', value);
      $('.placement-pointer-text').show();
      if (_placement.info.options.show_value) {
        var pointerTxt = $(drag).find('.placement-label').text();
        if ($(drag).find('.placement-label em').length > 0) {
          // update value
          $(drag).find('.placement-label em').html(value);
        } else {
          // add value
          $(drag)
            .find('.placement-label')
            .html(pointerTxt + '<em>' + value + '</em>');
        }
      }
      _placement.place_pointer_text(
        $(_placement.info.slider_id + ':visible'),
        ui ? ui.draggable : drag
      );
      _placement.init_progress(_placement.info);
    },

    init_drop_methods: function (info) {
      _placement.info = info;
      _placement.drop_notsure = $('#placement-notsure').droppable(
        'option',
        'drop'
      );
      _placement.drop_options = $('.placement-options').droppable(
        'option',
        'drop'
      );
    },

    handleAccessibilityMenu: function () {
      var self = this;
      var event = new CustomEvent('load-accessible-menu', {
        detail: {
          actions: {
            selectMenuItem: function (node) {
              var sourceId = $(node).data('source'),
                placement = $(node).data('placement');
              self.setPlacement($('#' + sourceId), '#placement-' + placement);
            },
            moveNode: function (node, direction) {
              if (
                $(node.closest('.ui-droppable')).hasClass('placement-slider')
              ) {
                var source = node.closest('.placement-indicator');
                self.movePlacement($(source), direction);
              }
            },
          },
          triggers: {
            button: {
              keydown: true,
              click: false,
            },
            menu: {
              keydown: true,
              click: true,
              mouseover: true,
            },
          },
          view: _placement.is_vertical() ? 'vertical' : 'horizontal',
        },
      });
      window.dispatchEvent(event);
    },
    setPlacement: function (source, placement, initial = true) {
      source.position({
        my: 'center',
        at: 'center',
        of: placement,
      });
      if (placement === '#placement-drop') {
        _placement.drop_slider(null, null, source);
        _placement.stop_slider(null, null, source, 50);
        _placement.init_sliders(_placement.info);
        //Reloading set placement to force located centered
        if (initial) _placement.setPlacement(source, '#placement-drop', false);
      } else {
        _placement.drop_notsure(null, null, source);
      }
    },

    movePlacement: function (source, direction) {
      var pointer_id = source.attr('id').replace(/(item)/, 'value');
      var value = parseInt($('#' + pointer_id).attr('value'));

      switch (direction) {
        case 'up':
          if (value > _placement.info.options.value_min) {
            source.animate(
              {
                top: '-=3',
              },
              10
            );
          }
          break;
        case 'down':
          if (value < _placement.info.options.value_max) {
            source.animate(
              {
                top: '+=3',
              },
              10
            );
          }
          break;
        case 'left':
          if (value > _placement.info.options.value_min) {
            source.animate(
              {
                left: '-=3',
              },
              10
            );
          }
          break;
        case 'right':
          if (value < _placement.info.options.value_max) {
            source.animate(
              {
                left: '+=3',
              },
              10
            );
          }
          break;
      }
      _placement.stop_slider(null, null, source);
    },

    init: function (self, options) {
      var info = {};
      $.extend(info, options);

      _placement.init_notsure(info);
      _placement.init_plcslider(info);
      _placement.init_placement_options(info);
      _placement.init_sliders(info);
      _placement.init_progress(info);

      if (info.options.accesibility) {
        _placement.handleAccessibilityMenu();
        _placement.init_drop_methods(info);
      }

      // handle window resizes
      var $onResize = debounce(function () {
        _placement.init_notsure(info);
        _placement.init_plcslider(info);
        _placement.init_placement_options(info);
        _placement.init_sliders(info);
        _placement.init_progress(info);
      }, 600);
      window.removeEventListener('resize', $onResize);
      window.addEventListener('resize', $onResize);
    },
  };

  $.fn.placement = function (action, options) {
    window.dk_margin_top = 40; // default
    var value = false;
    var result = this.each(function () {
      var self = $(this);
      if (_placement[action]) {
        value = _placement[action](self, options);
      } else {
        if (!action) {
          options = {};
        } else {
          options = action;
        }
        action = 'init';
        value = _placement[action](self, options);
      }
    });
    return value !== false ? value : result;
  };
})(jQuery);
