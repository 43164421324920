var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div role=\"alert\" tabindex=\"0\" class=\"alert alert-error alert-with-icon "
    + alias4(((helper = (helper = lookupProperty(helpers,"extra_classes") || (depth0 != null ? lookupProperty(depth0,"extra_classes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"extra_classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":72},"end":{"line":1,"column":89}}}) : helper)))
    + "\" style=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"style") || (depth0 != null ? lookupProperty(depth0,"style") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"style","hash":{},"data":data,"loc":{"start":{"line":1,"column":98},"end":{"line":1,"column":107}}}) : helper)))
    + "\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"input_id") || (depth0 != null ? lookupProperty(depth0,"input_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"input_id","hash":{},"data":data,"loc":{"start":{"line":1,"column":113},"end":{"line":1,"column":125}}}) : helper)))
    + "_note\">\n  <i class=\"fa fa-exclamation-circle\"></i>\n    <span role=\"note\" id=\"error_input_id\" class=\"sr-only\" tabindex=\"0\"></span>\n  "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"error_message") || (depth0 != null ? lookupProperty(depth0,"error_message") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"error_message","hash":{},"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":4,"column":21}}}) : helper))) != null ? stack1 : "")
    + "\n</div>";
},"useData":true});