(function ($, widgets) {
  var DynGrid = widgets.Widget.extend(
    {
      constructor: function DynGridConstructor(object) {
        DynGrid.__super__.constructor.call(this, object);

        // It's okay to replace nav handlers, because they're reset to
        // their defaults on reaching a new page.
        mainNav.nextButton.bind('click keypress', $.proxy(this, 'next'));
        mainNav.backButton.bind('click keypress', $.proxy(this, 'previous'));

        /**
         * @private
         */
        this._completed_phases = object.completed_phases || {};
      },

      render: function () {
        DynGrid.__super__.render.call(this);
        var self = this;

        var columns =
          this._object.columns || this._object.response_options.length;

        if (this._object.row_align && this._object.row_align !== 'null') {
          $('.dyngrid-category-text').addClass(
            'row-align-' + this._object.row_align
          );
        } else {
          $('.dyngrid-category-text').addClass('row-align-language');
        }

        this.phases = $.map(this.$el.find('[data-phase]'), function (phaseEl) {
          var $phaseEl = $(phaseEl);
          var $responses = $phaseEl.find('.response-button');
          var responseListContainer = $phaseEl.find('.question-response-list');
          var config = {
            columns: columns,
            minColumns: self._object.min_columns,
            thresholds: [widgets.MEDIA_SKINNY_MAX_WIDTH],
            wrap: self._object.wrap,
            horizontal: self._object.horizontal,
            onLayout: $.proxy(self, '_onLayout'),
          };
          var layout = new Gryphon.widgets.layout.DynamicRowLayout(
            responseListContainer,
            $responses,
            config
          );
          self._initResponseElements(layout);
          layout.render();
          return { $el: $phaseEl, layout: layout };
        });

        // Legacy nonsense for detecting whether to enable/disable nav buttons
        this.zero_phase_back = $('#back_button').css('display') != 'none';
        this.last_phase_next = $('#next_button').css('display') != 'none';

        this._setPhase(this._object.current_phase);
        this._setAriaValues(this.phases[this._object.current_phase]);

        $('.dk-check').on('click', function () {
          if ($('.dk-check').attr('checked')) {
            $('.response-button').addClass('hidden-response');
          } else {
            $('.response-button').removeClass('hidden-response');
          }
        });
      },

      next: function (ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        this._setPhaseCompletion();
        var next = this._object.current_phase + 1;
        var total = this._object.total_phases;
        if (next >= total) {
          click_next({ key: ev.key, type: ev.type });
        } else {
          this._setPhase(next);
          this._setAriaValues(this.phases[next]);
          if (next > 0) {
            this._refreshQuestionTextRole(this._object, next);
          }
        }
      },

      previous: function (ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        this._setPhaseCompletion();
        var prev = this._object.current_phase - 1;
        if (prev < 0) {
          click_back({ key: ev.key, type: ev.type });
        } else {
          this._setPhase(prev);
          this._setAriaValues(this.phases[prev]);
          if (prev < this._object.category_options.length - 1) {
            this._refreshQuestionTextRole(this._object, prev);
          }
        }
      },

      _onLayout: function _onLayout(layout) {
        this._resizeButtonsForLayout(layout);
        window.scrollTo(0, 0);
      },

      _initResponseElements: function (layout) {
        // https://confluence.yougov.net/display/SSPD/Enhanced+Questions#heading-Dynamicgrids
        const SUPPORTED_COLORS = [
          'blue',
          'brown',
          'gray',
          'green',
          'orange',
          'purple',
          'red',
          'teal',
          'yellow',
        ];
        var options = {};

        if (this._object.autoadvance) {
          options.onChange = $.proxy(function (checked, event) {
            if (checked) {
              this.next(event);
            }
          }, this);
        }

        var $responses = layout.elements;
        $.each(this._object.response_options, function (idx, response) {
          const opts = {
            ...response.options,
            color:
              SUPPORTED_COLORS.indexOf(response.options.color) !== -1
                ? response.options.color
                : 'teal',
            onChange: options.onChange,
          };
          $($responses[idx]).responsebutton(opts);
        });
        this._equaliseButtonHeightsOnImageLoad($responses, layout);
      },

      _equaliseButtonHeightsOnImageLoad: function (buttons, layout) {
        var images = buttons.find('img');
        var equaliseHeights = $.throttle(
          Gryphon.widgets.RELAYOUT_PERIOD,
          $.proxy(function () {
            this._resizeButtonsForLayout(layout);
          }, this)
        );
        images.one('load', equaliseHeights);
      },

      _resetButtonSizes: function _resetButtonSizes(buttons) {
        buttons.css('display', '').width('auto').height('auto');
      },

      _equaliseButtonSizes: function _equaliseButtonSizes(buttons, dimension) {
        buttons.equalisr(dimension);
      },

      /**
       * Resize buttons based on the provided layout.
       *
       * @param {object} layout The layout instance.
       */
      _resizeButtonsForLayout: function (layout) {
        var buttons = layout.elements;
        var thresholdWidth = layout._object.thresholds[0];
        var isLargeDevice = layout.status.windowWidth > thresholdWidth;

        this._resetButtonSizes(buttons);
        buttons.responsebutton('hyphenate');
        // TODO: the size can be archived with css only
        if (isLargeDevice || layout.status.columns > 1) {
          if (layout.status.columns === 1) {
            this._equaliseButtonSizes(buttons, 'width');
          }
          this._equaliseButtonSizes(buttons, 'height');
        }

        var compactClasses =
          'response-button-square-bottom ' +
          'response-button-square-top ' +
          'response-button-no-bottom-border';
        if (
          !isLargeDevice &&
          layout.status.columns === 1 &&
          layout.elements.length >= 8
        ) {
          layout.elements.removeClass('response-button-margin');
          layout.elements.addClass(compactClasses);
          layout.elements.first().removeClass('response-button-square-top');
          layout.elements
            .last()
            .removeClass(
              'response-button-square-bottom ' +
                'response-button-no-bottom-border'
            );
        } else {
          layout.elements.addClass('response-button-margin');
          layout.elements.removeClass(compactClasses);
        }
      },

      /**
       * Private method that actually does the work of changing phase.
       */
      _setPhase: function (phase) {
        var self = this;
        mainNav.disable();

        // Scroll to top, fade out old phase, show new phase, show nav buttons
        if ($(window).scrollTop() > 0 && $.scrollTo) {
          try {
            $.scrollTo(0, transitionPhases);
          } catch (e) {}
        } else {
          transitionPhases();
        }

        function transitionPhases() {
          var oldPhase = self.phases[self._object.current_phase];
          var newPhase = self.phases[phase];
          oldPhase.layout.elements.responsebutton('disable');
          oldPhase.$el.fadeOut(500, function () {
            oldPhase.$el.addClass('dyngrid-phase-hidden');
            newPhase.$el
              .removeClass('dyngrid-phase-hidden')
              // Undo display: none style from fadeOut
              .show();

            var buttons = newPhase.layout.elements;
            buttons.responsebutton('enable');
            self._onLayout(newPhase.layout);

            self._object.current_phase = phase;
            self._setNavButtonVisibility();
          });
        }
      },

      _setAriaValues: function (currentPage) {
        var previousAnswer = currentPage.$el.find('.sr-only');
        const checkedElement =
          'input[type=' + this._object.input_type + ']:checked';
        let parentElementChecked = currentPage.$el
          .find(checkedElement)
          .parent();

        if (parentElementChecked.length > 0) {
          let aria_label_all = [];
          $.each(parentElementChecked, function (index, element) {
            aria_label_all.push(element.innerText);
          });
          previousAnswer.text(
            'Previous answer' + ' ' + aria_label_all.toString()
          );
        } else {
          previousAnswer.text('');
        }
      },

      _setPhaseCompletion: function () {
        var current = this._object.current_phase;
        this._completed_phases[current] = Boolean(
          this.phases[current].$el.find('input:checked').length
        );
      },

      _refreshQuestionTextRole: function (object, phase) {
        $('.question-text').removeAttr('role');
        $('.question-text').attr({
          'aria-label': object.text + ',' + object.category_options[phase].text,
          role: 'alert',
        });
      },

      /**
       * This method is largely copy/pasted from legacy code and really should
       * be implemented better.
       */
      _setNavButtonVisibility: function () {
        var current = this._object.current_phase;
        var button_states = {
          back_button: true,
          next_button: true,
        };

        // FIXME This has to be the absolute worst way of setting the buttons
        // because there is already other logic in play in survey.js for
        // set_button_visibility that has nothing to do with what we are doing
        // here. What are we supposed to do when `this._object.required` is soft?
        if (this._object.required !== 'NONE' && this._object.autoadvance) {
          if (!this._completed_phases[current]) {
            button_states.next_button = false;
          }
        }

        if (current === 0) {
          button_states.back_button = this.zero_phase_back;
        } else if (
          current == this._object.total_phases - 1 &&
          !this._object.required
        ) {
          button_states.next_button = this.last_phase_next;
        }

        set_nav_button_visibility(button_states);
      },
    },
    {
      types: ['dyngrid', 'dyngrid-check'],
      views: ['dyngrid', 'dyngrid-check'],
    }
  );

  DynGrid.register();
  widgets.DynGrid = DynGrid;
})(jQuery, (Gryphon.widgets = Gryphon.widgets || {}));
