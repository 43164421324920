var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"dyngrid-phase dyngrid-phase-hidden\" data-phase=\""
    + container.escapeExpression(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">\n          <div class=\"dyngrid-category-text\">\n            "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"0") : stack1), depth0)) != null ? stack1 : "")
    + "\n          </div>\n          <div class=\"question-response-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"response_options") : depths[1]),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":19,"column":21}}})) != null ? stack1 : "")
    + "          </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = __default(require("../js/helpers/partial.js")).call(alias1,"response-button",{"name":"partial","hash":{"answer":(depths[1] != null ? lookupProperty(depths[1],"answer") : depths[1]),"group":(depths[1] != null ? lookupProperty(depths[1],"name") : depths[1]),"input_class":(depth0 != null ? lookupProperty(depth0,"input_class") : depth0),"text":(depth0 != null ? lookupProperty(depth0,"text") : depth0),"input_name":__default(require("../js/helpers/get.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"response_inputs") : depths[1]),(data && lookupProperty(data,"index")),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":13,"column":25},"end":{"line":13,"column":56}}}),"input_type":(depths[2] != null ? lookupProperty(depths[2],"input_type") : depths[2]),"code":(depth0 != null ? lookupProperty(depth0,"code") : depth0)},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"hidden\" name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\" />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"render") : depth0), depth0))
    + "-layout\">\n    <div class=\"dyngrid\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"category_options") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":22,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n"
    + ((stack1 = __default(require("../js/helpers/eachkeys.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"passthru") : depth0),{"name":"eachkeys","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":0},"end":{"line":27,"column":13}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});