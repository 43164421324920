var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../js/helpers/ifanswerexists.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"ifanswerexists","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":8,"column":23}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div role=\"note\" class=\"sr-only\" tabindex=\"0\" id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "_note\">\n        Previous answer is \n      </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./error.hbs"),depth0,{"name":"error","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div role=\"note\" class=\"sr-only\" tabindex=\"0\">\n        Scale element with the minimum value labelled as \""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"0") : stack1), depth0)) != null ? stack1 : "")
    + "\", and the maximum value labelled as \""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"1") : stack1), depth0)) != null ? stack1 : "")
    + "\"\n      </div>\n      <tr class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">\n        <td id=\"orientation_content_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" colspan=\"3\">\n          <div class=\"scale-container\" role=\"radiogroup\">\n            <div class=\"scale-label-left\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"0") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n            <div class=\"scale-options\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"values") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":14},"end":{"line":36,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"scale-label-right\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"1") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n          </div>\n        </td>\n      </tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"dont_know") : depths[1]),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":6},"end":{"line":49,"column":13}}})) != null ? stack1 : "")
    + "      <tr class=\"hide\">\n        <td colspan=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"colcount") : depths[1]), depth0))
    + "\">\n          <input type=\"hidden\" name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" value=\""
    + alias2(__default(require("../js/helpers/ans.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"answer") : depth0),(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"ans","hash":{},"data":data,"loc":{"start":{"line":52,"column":54},"end":{"line":52,"column":73}}}))
    + "\" />\n        </td>\n      </tr>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div\n                  id=\"r-"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"name") : depths[1]), depth0))
    + "-"
    + alias2(alias1(depth0, depth0))
    + "\"\n                  class=\"scale_button scale_cell_value\"\n                  role=\"radio\"\n                  aria-checked=\"false\"\n                  tabindex=\"0\"\n                  onkeydown=\"scale_select_tab(event, '"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"name") : depths[1]), depth0))
    + "', '"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"name_dk") : depths[1]), depth0))
    + "', "
    + alias2(alias1(depth0, depth0))
    + ")\"\n                  onclick=\"scale_select('"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"name") : depths[1]), depth0))
    + "', '"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"name_dk") : depths[1]), depth0))
    + "', "
    + alias2(alias1(depth0, depth0))
    + ")\"\n                >\n                  <span>"
    + alias2(alias1(depth0, depth0))
    + "</span> \n                </div>\n";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <tr class=\"scale_dont_know dk-input\">\n        <td colspan=\""
    + container.escapeExpression(container.lambda((depths[2] != null ? lookupProperty(depths[2],"colcount") : depths[2]), depth0))
    + "\">\n          "
    + ((stack1 = __default(require("../js/helpers/partial.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"dk-partial",{"name":"partial","hash":{"dk_value":997,"dk_id":(depth0 != null ? lookupProperty(depth0,"name_dk") : depth0),"dk_text":(depths[1] != null ? lookupProperty(depths[1],"dk_text") : depths[1])},"data":data,"loc":{"start":{"line":45,"column":10},"end":{"line":46,"column":26}}})) != null ? stack1 : "")
    + "\n        </td>\n      </tr>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <input type=\"hidden\" name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\" />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"question question-scale\">\n  <legend class=\"question-text\" id=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-label\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,(depth0 != null ? lookupProperty(depth0,"category_options") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"error_message") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":2},"end":{"line":12,"column":9}}})) != null ? stack1 : "")
    + "  <div class=\"scale_table_container\">\n    <table class=\"scale_table\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,(depth0 != null ? lookupProperty(depth0,"category_options") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":6},"end":{"line":55,"column":15}}})) != null ? stack1 : "")
    + "    </table>\n  </div>\n"
    + ((stack1 = __default(require("../js/helpers/eachkeys.js")).call(alias2,(depth0 != null ? lookupProperty(depth0,"passthru") : depth0),{"name":"eachkeys","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":2},"end":{"line":60,"column":15}}})) != null ? stack1 : "")
    + "</fieldset>";
},"usePartial":true,"useData":true,"useDepths":true});