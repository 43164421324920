var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\"\n  ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = __default(require("../js/helpers/ifanswer.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"dk_id") : depth0),"on",{"name":"ifanswer","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":11},"end":{"line":4,"column":65}}})) != null ? stack1 : "")
    + " ";
},"4":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<label role=\"checkbox\" class=\"dk-input "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"narrow_view") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"wide_view") : depth0), depth0))
    + " dk-align-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"dk_align") : depth0), depth0))
    + "\" for=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"dk_id") : depth0), depth0))
    + "\">\n  <input type=\"checkbox\" id=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"dk_id") : depth0), depth0))
    + "\" class=\"dk-check\" name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"dk_id") : depth0), depth0))
    + "\"\n  data-idx=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"index") : depth0), depth0))
    + "\" "
    + ((stack1 = __default(require("../js/helpers/ifans.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answer") : depth0),(depth0 != null ? lookupProperty(depth0,"response_id") : depth0),(depth0 != null ? lookupProperty(depth0,"dk_value") : depth0),{"name":"ifans","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":23},"end":{"line":4,"column":76}}})) != null ? stack1 : "")
    + ">\n  <span class=\"dk-label\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"dk_text") : depth0), depth0)) != null ? stack1 : "")
    + "</span>\n</label>\n";
},"useData":true});