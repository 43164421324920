var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "accept=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"file_types") : depth0), depth0))
    + "\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "multiple";
},"5":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"upload-container-dk\">\n      "
    + ((stack1 = __default(require("../js/helpers/partial.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"dk-partial",{"name":"partial","hash":{"dk_id":(depth0 != null ? lookupProperty(depth0,"dk_id") : depth0),"dk_value":997,"response_id":(depth0 != null ? lookupProperty(depth0,"input_id") : depth0),"dk_text":(depth0 != null ? lookupProperty(depth0,"dk_text") : depth0)},"data":data,"loc":{"start":{"line":43,"column":6},"end":{"line":43,"column":95}}})) != null ? stack1 : "")
    + "\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"question question-upload\">\n"
    + ((stack1 = container.invokePartial(require("./error.hbs"),depth0,{"name":"error","hash":{"extra_classes":"hide"},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  <legend class=\"question-text\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-label\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</legend>\n  <div class=\"question-response-form\">\n    <div class=\"question-upload-helper-text\">\n      <p>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"you_can_upload_to") : depth0), depth0)) != null ? stack1 : "")
    + "</p>\n      <div>\n        <p>"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"upload_supported_formats") : depth0), depth0)) != null ? stack1 : "")
    + " "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"file_types") : depth0), depth0)) != null ? stack1 : "")
    + "</p>\n        <p class=\"align-right\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"max_file_size_mb_text") : depth0), depth0)) != null ? stack1 : "")
    + "</p>\n      </div>\n    </div>\n    <div class=\"upload-container\" id=\"upload-dropbox-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\">\n      <div class=\"input-container\">\n        <label for=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"drag_text") : depth0), depth0))
    + "&nbsp&nbsp</label>\n        <div>\n          <button\n            class=\"response-button\"\n            role=\"button\"\n            tabindex=\"0\"\n            style=\"user-select: none\"\n          >\n             "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"btn_text") : depth0), depth0))
    + "\n            <i class=\"response-button-icon\">\n                <svg width=\"18\" height=\"18\" viewBox=\"0 0 18 18\" xmlns=\"http://www.w3.org/2000/svg\">\n                    <g stroke=\"#FFF\" stroke-width=\"2.5\" fill=\"none\" fill-rule=\"evenodd\">\n                        <path d=\"M13.285 6.708 8.581 2.005 3.877 6.708M8.55 2.13v9.24M3.6 16.65h9.9\"/>\n                    </g>\n                </svg>\n            </i>\n          </button>\n        </div>        \n        <input type=\"file\" id=\"r-upload-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\" name=\"upload-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\" onclick=\"this.value=null;\"\n        class=\"file-input\" data-min-number=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"min_number") : depth0), depth0))
    + "\"\n        data-max-number=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"max_number") : depth0), depth0))
    + "\" data-max-size=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"max_filesize_MB") : depth0), depth0))
    + "\"\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"file_types") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":8},"end":{"line":35,"column":56}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"multiple") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":57},"end":{"line":36,"column":33}}})) != null ? stack1 : "")
    + ">        \n        <input type=\"hidden\" id=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\" name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\" />\n      </div>\n    </div>\n    <div id=\"upload-filelist-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "\" class=\"file-list "
    + ((stack1 = __default(require("../js/helpers/ifanswer.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"dk_id") : depth0),"on",{"name":"ifanswer","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":60},"end":{"line":40,"column":105}}})) != null ? stack1 : "")
    + "\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"dk") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":4},"end":{"line":45,"column":11}}})) != null ? stack1 : "")
    + "    <div id=\"upload-reminder\">\n      <p class=\"question-upload-reminder\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"upload_reminder") : depth0), depth0))
    + "</p>\n    </div>\n  </div>\n</fieldset>\n<div \n  id=\"unsavedModal\"\n  class=\"modal hide fade\"\n  role=\"dialog\"\n  aria-label=\"YouGov.com says\"\n  aria-hidden=\"true\">\n  <div class=\"modal-body\" data-testid=\"unsaved\">\n    <p>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"upload_modal_leave_prompt") : depth0), depth0))
    + "<br />"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"upload_modal_data_loss_warning") : depth0), depth0))
    + "</p>\n  </div>\n  <div class=\"modal-footer\">\n    <button type=\"button\" class=\"btn\" id=\"unsavedModalLeaveBtn\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"upload_modal_confirm_leave") : depth0), depth0))
    + "</button>\n    <button type=\"button\" class=\"btn btn-primary\" data-dismiss=\"modal\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"upload_modal_confirm_stay") : depth0), depth0))
    + "</button>\n  </div>\n</div>\n<div\n  id=\"deleteModal\"\n  class=\"modal hide fade\"\n  role=\"dialog\"\n  aria-label=\"YouGov.com says\"\n  aria-hidden=\"true\">\n  <div class=\"modal-header\">\n    <h4>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"upload_delete_confirm") : depth0), depth0))
    + "</h4>\n    <button type=\"button\" class=\"btn-close\" data-dismiss=\"modal\" aria-hidden=\"true\">\n      <span class=\"ygicon-popup-close\"></span>\n    </button>\n  </div>\n  <div class=\"modal-body\" data-testid=\"deleteFile\">\n    <div id=\"upload-filelist-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-modal\" class=\"file-list-modal\"></div>\n  </div>\n  <div class=\"modal-footer\">\n    <button type=\"button\" class=\"btn\" data-dismiss=\"modal\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"upload_cancel") : depth0), depth0))
    + "</button>\n    <button type=\"button\" class=\"btn btn-primary\" id=\"deleteModalConfirmBtn\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"upload_delete_yes") : depth0), depth0))
    + "</button>\n  </div>\n</div>\n\n";
},"usePartial":true,"useData":true});