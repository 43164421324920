(function ($) {
  // The Popup jQuery widget
  // see http://jqueryui.com/docs/Developer_Guide
  var Popup = {
    options: {
      hidden: true,
    },

    _create: function () {
      this.options.root = this.element;
      var el = this.element;
      var required = this.element.data('required');
      var soft_required_prompts = 0;
      var trigger = this.trigger();
      var handler = $.proxy(this, '_onTriggerElementClick');
      $(trigger).click(handler);
      // Event listener for Bootstrap modal's 'hide' event when dismissing the popup
      // Special validation handling for 'required' option
      var that = this;
      this.element.on('hide', function (event) {
        if (
          required === 'HARD' ||
          (required === 'SOFT' && soft_required_prompts == 0)
        ) {
          // If required=HARD or SOFT (with no previous prompts) and no inputs are selected
          // do not dismiss the modal / popup
          if (!el.find('input').is(':checked')) {
            event.preventDefault();
            soft_required_prompts++;

            var response_list_el = el.find('.question-response-list'),
              error = response_list_el.find('.alert-error');

            if (error.length > 0) {
              error.text(el.data('required-text'));
            } else {
              response_list_el.prepend(
                '<div class="alert alert-error">' +
                  el.data('required-text') +
                  '</div>'
              );
            }
          } else {
            // clear existing validation message
            el.find('.alert-error').remove();
          }
        }
        that.hidden(true);
      });
    },

    // set the widget hidden status
    hidden: function (hidden) {
      this.options.hidden = hidden;
    },

    _onTriggerElementClick: function (event) {
      this.toggle();
      event.stopPropagation();
    },

    // toggles the hide/show of the widget
    toggle: function () {
      var is_hidden = this.options.hidden;
      if (is_hidden) {
        if (this._before_popup(this)) {
          this.show();
        }
      } else {
        this.hide();
      }
    },

    show: function () {
      // first, show the overlay, preventing the user from
      //  navigating forward or back (or triggering other answers)
      //  while the popup is being rendered slowly.
      this._show_modal();
      this.element.show('fast', $.proxy(this, '_afterShow'));
    },

    _afterShow: function () {
      this._listen_changes();
      this.hidden(false);
    },

    _listen_changes: function () {
      var self = this;
      $('.response-input')
        .off('change')
        .on('change', function () {
          self._hide_modal();
        });
    },

    hide: function () {
      this.element.hide('fast', $.proxy(this, '_afterHide'));
    },

    _afterHide: function () {
      this._hide_modal();
      this.hidden(true);
    },

    /*
     * This verifies the trigger input is selected before popping
     * up the popup
     */
    _before_popup: function () {
      var trigger = this.trigger();
      return trigger.is(':checked');
    },

    _show_modal: function () {
      this.element.modal('show');
    },

    _hide_modal: function () {
      this.element.modal('hide');
    },

    /*
     * Returns the trigger in order to help perform look ups on
     * dependent widget inputs
     */
    trigger: function () {
      return this.element.closest('.w-choice-base').find('input.top-level');
    },
  };

  $.widget('yougov.yg_popup', Popup);
})(jQuery);
