var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./error.hbs"),depth0,{"name":"error","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./matrix-table.hbs"),depth0,{"name":"matrix-table","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./matrix-flashcards.hbs"),depth0,{"name":"matrix-flashcards","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./matrix-cards.hbs"),depth0,{"name":"matrix-cards","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"question question-matrix question-matrix-render-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"render") : depth0), depth0))
    + "\">\n    <legend class=\"question-text\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-label\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"error_message") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + "  <div class=\"matrix-container\">\n        <!-- NOTE: Partial for table-based displays of the matrix widget, etc etc -->\n        <!-- This would allow this single template to handle multiple 'partials' based on the 'render' option -->\n"
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"render") : depth0),"list",{"name":"ifequal","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":11,"column":20}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"render") : depth0),"flashcards",{"name":"ifequal","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":14,"column":20}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"render") : depth0),"cards",{"name":"ifequal","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":17,"column":20}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"render") : depth0),"input_box",{"name":"ifequal","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":20,"column":20}}})) != null ? stack1 : "")
    + "    </div>\n</fieldset>\n";
},"usePartial":true,"useData":true});