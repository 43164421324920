var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias2(__default(require("../js/helpers/set.js")).call(alias1,depth0,{"name":"set","hash":{"phase_index":(data && lookupProperty(data,"index"))},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":31}}}))
    + "\n<div class=\"accordion\" id=\"phase-"
    + alias2(alias3((data && lookupProperty(data,"index")), depth0))
    + "\" "
    + ((stack1 = __default(require("../js/helpers/ifnotphase.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"phase_pos") : depths[1]),(data && lookupProperty(data,"index")),{"name":"ifnotphase","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":45},"end":{"line":3,"column":117}}})) != null ? stack1 : "")
    + " data-phase=\""
    + alias2(alias3((data && lookupProperty(data,"index")), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,depth0,{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":71,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"varname") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":7},"end":{"line":5,"column":47}}})) != null ? stack1 : "")
    + " class=\"accordion-group accordion-caret accordion-caret-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"collapse_expanded") : depths[2]),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":5,"column":103},"end":{"line":5,"column":166}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../js/helpers/contains.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"varname") : depth0),(depths[2] != null ? lookupProperty(depths[2],"category_errors") : depths[2]),{"name":"contains","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":167},"end":{"line":5,"column":244}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"accordion-heading\">\n      <a\n        role=\"button\"\n        aria-expanded=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"collapse_expanded") : depths[2]),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":9,"column":23},"end":{"line":9,"column":78}}})) != null ? stack1 : "")
    + "\"\n        aria-controls=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"varname") : depth0), depth0))
    + "\"\n        aria-label=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"0") : stack1), depth0))
    + "\"\n        id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"varname") : depth0), depth0))
    + "-label\"\n        class=\"accordion-toggle "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"collapse_expanded") : depths[2]),{"name":"unless","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":32},"end":{"line":13,"column":87}}})) != null ? stack1 : "")
    + "\"\n        data-toggle=\"collapse\"\n        data-parent=\"#phase-"
    + alias3(alias2((depths[1] != null ? lookupProperty(depths[1],"phase_index") : depths[1]), depth0))
    + "\"\n        href=\"#"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"varname") : depth0), depth0))
    + "\">\n        <div style=\"content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"rowsum") : depths[2]),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":20,"column":19}}})) != null ? stack1 : "")
    + "            <i class=\"fa fa-check\"></i><span class=\"accordion-category-text\" aria-hidden=\"true\">"
    + ((stack1 = alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"0") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n        </div>\n        <div class=\"toggle\">\n            <i class=\"fa fa-chevron-down\"></i>\n            <i class=\"fa fa-chevron-up\"></i>\n        </div>\n      </a>\n    </div>\n    <div id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"varname") : depth0), depth0))
    + "\" class=\"accordion-body collapse "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"collapse_expanded") : depths[2]),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":57},"end":{"line":29,"column":97}}})) != null ? stack1 : "")
    + "\">\n      <div class=\"accordion-inner\">\n        <ul class=\"unstyled\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depths[2] != null ? lookupProperty(depths[2],"response_options") : depths[2]),{"name":"each","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":10},"end":{"line":48,"column":19}}})) != null ? stack1 : "")
    + "        </ul>\n      </div>\n    </div>\n  </div>\n  <script>\n    (function () {\n      var grid = $('#phase-"
    + alias3(alias2((depths[1] != null ? lookupProperty(depths[1],"phase_index") : depths[1]), depth0))
    + "');\n      var expanded = 'accordion-caret-expanded';\n      var collapsed = 'accordion-caret-collapsed';\n      var setClasses = function (event) {\n        var el = $(event.target).closest('.accordion-caret');\n        if (event.type === 'show') {\n          el.addClass(expanded)\n            .removeClass(collapsed);\n        } else if (event.type === 'hide') {\n          el.addClass(collapsed)\n            .removeClass(expanded);\n        }\n      };\n      grid.on('show.collapse hide.collapse', setClasses);\n    })();\n  </script>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "id=\"r-"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"varname") : depth0), depth0))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    return "expanded";
},"9":function(container,depth0,helpers,partials,data) {
    return "collapsed";
},"11":function(container,depth0,helpers,partials,data) {
    return "accordion-group-error";
},"13":function(container,depth0,helpers,partials,data) {
    return "true";
},"15":function(container,depth0,helpers,partials,data) {
    return "false";
},"17":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"badge pull-right\">0</span>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return "in";
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li class=\"grid-cell\">\n            "
    + ((stack1 = __default(require("../js/helpers/partial.js")).call(alias1,"grid-cell-input",{"name":"partial","hash":{"label_resp":__default(require("../js/helpers/get.js")).call(alias1,(depths[3] != null ? lookupProperty(depths[3],"response_options") : depths[3]),(data && lookupProperty(data,"index")),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":45,"column":25},"end":{"line":45,"column":63}}}),"label_cat":__default(require("../js/helpers/get.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"text") : depths[1]),0,{"name":"get","hash":{},"data":data,"loc":{"start":{"line":44,"column":24},"end":{"line":44,"column":39}}}),"dk_id":(depths[3] != null ? lookupProperty(depths[3],"dk_id") : depths[3]),"answer":(depths[1] != null ? lookupProperty(depths[1],"answer") : depths[1]),"text":(depth0 != null ? lookupProperty(depth0,"text") : depth0),"skinny":true,"input_class":(depth0 != null ? lookupProperty(depth0,"input_class") : depth0),"input_type":(depths[3] != null ? lookupProperty(depths[3],"input_type") : depths[3]),"code":(depth0 != null ? lookupProperty(depth0,"code") : depth0),"category_name":(depths[1] != null ? lookupProperty(depths[1],"name") : depths[1]),"name":__default(require("../js/helpers/get.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"response_inputs") : depths[1]),(data && lookupProperty(data,"index")),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":35,"column":19},"end":{"line":35,"column":50}}})},"data":data,"loc":{"start":{"line":34,"column":12},"end":{"line":46,"column":15}}})) != null ? stack1 : "")
    + "\n          </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"phases") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":73,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});