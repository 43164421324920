var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<script type='text/javascript'>\n  function openLink(browser) {\n    var link = '';\n    switch (browser) {\n      case 'chrome':\n        link = 'https://www.google.com/chrome/'\n        break;\n      case 'edge':\n        link = 'https://www.microsoft.com/edge/'\n        break;\n      case 'firefox':\n        link = 'https://www.mozilla.org/'\n        break;\n      case 'opera':\n        link = 'https://www.opera.com/'\n        break;\n      case 'safari':\n        link = 'https://www.apple.com/safari/'\n        break;\n      default:\n        link = '#';\n    }\n    window.open(link, '_blank').focus();\n  }\n</script>\n<script type='text/javascript'>\n  function copyLink(event){\n    event.preventDefault();\n  	var elem = document.createElement('textarea');\n   	elem.value = document.URL;\n   	document.body.appendChild(elem);\n   	elem.select();\n   	document.execCommand('copy');\n   	document.body.removeChild(elem);\n  	alert('Link to Questionnaire copied!');\n  }\n</script>\n<script type='text/javascript'>\n  function continueToQuestionnaire(event){\n    event.preventDefault();\n\n    var domain = location.hostname.split('.');\n    var mainDomain = domain.pop();\n    if (mainDomain !== 'localhost') mainDomain = '.' + domain.pop() + '.' + mainDomain;\n\n    document.cookie = 'icecat=1;domain=' + mainDomain + ';path=/';\n\n	  window.location.href = document.URL;\n  }\n</script>\n<div class='unsupported-container'>\n  <div class='title'>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"template_vars") : depth0)) != null ? lookupProperty(stack1,"title") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n  <div class='text'>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"template_vars") : depth0)) != null ? lookupProperty(stack1,"main") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n  <div class='browser-list'>\n    <div class='browser' onclick=\"openLink('chrome')\">\n      <div class='logo'><img src='/static/img/unsupported/chrome_logo.png' alt='Google Chrome'/></div>\n      <div class='row'>\n        <div class='name'>Google Chrome</div>\n        <div class='icon'></div>\n      </div>\n    </div>\n    <div class='browser' onclick=\"openLink('edge')\">\n      <div class='logo'><img src='/static/img/unsupported/edge_logo.png' alt='Microsoft Edge'/></div>\n      <div class='row'>\n        <div class='name'>Microsoft Edge</div>\n        <div class='icon'></div>\n      </div>\n    </div>\n    <div class='browser' onclick=\"openLink('firefox')\">\n      <div class='logo'><img src='/static/img/unsupported/firefox_logo.png' alt='Mozilla Firefox'/></div>\n      <div class='row'>\n        <div class='name'>Mozilla Firefox</div>\n        <div class='icon'></div>\n      </div>\n    </div>\n    <div class='browser' onclick=\"openLink('opera')\">\n      <div class='logo'><img src='/static/img/unsupported/opera_logo.png' alt='Opera'/></div>\n      <div class='row'>\n        <div class='name'>Opera</div>\n        <div class='icon'></div>\n      </div>\n    </div>\n    <div class='browser' onclick=\"openLink('safari')\">\n      <div class='logo'><img src='/static/img/unsupported/safari_logo.png' alt='Safari'/></div>\n      <div class='row'>\n        <div class='name'>Safari</div>\n        <div class='icon'></div>\n      </div>\n    </div>\n  </div>\n  <div class='buttons'>\n    <button\n                name=\""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"template_vars") : depth0)) != null ? lookupProperty(stack1,"copy_link") : stack1), depth0)) != null ? stack1 : "")
    + "\"\n                data-role=\"button\"\n                tabindex=\"0\"\n                class='copy-link'\n                onclick='copyLink(event)'\n    >\n      <div class='icon'></div>\n      <div>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"template_vars") : depth0)) != null ? lookupProperty(stack1,"copy_link") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n    </button>\n    <button\n                class='continue'\n                name=\""
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"template_vars") : depth0)) != null ? lookupProperty(stack1,"continue") : stack1), depth0)) != null ? stack1 : "")
    + "\"\n                data-role=\"button\"\n                tabindex=\"0\"\n                onclick='continueToQuestionnaire(event)'\n              >\n      <div>"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"template_vars") : depth0)) != null ? lookupProperty(stack1,"continue") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n      <div class='icon'></div>\n    </button>\n  </div>\n</div>\n";
},"useData":true});