var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./error.hbs"),depth0,{"name":"error","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"dyngrid-phase dyngrid-phase-hidden\" data-phase=\""
    + container.escapeExpression(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">\n      <div class=\"dyngrid-category-text\">"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"0") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n      <div role=\"note\" class=\"sr-only\" tabindex=\"0\">\n      </div>\n      <div class=\"question-response-list\">\n        "
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"response_options") : depths[1]),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":16,"column":17}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = __default(require("../js/helpers/partial.js")).call(alias1,"response-button",{"name":"partial","hash":{"answer":(depths[1] != null ? lookupProperty(depths[1],"answer") : depths[1]),"group":(depths[1] != null ? lookupProperty(depths[1],"name") : depths[1]),"input_class":(depth0 != null ? lookupProperty(depth0,"input_class") : depth0),"text":(depth0 != null ? lookupProperty(depth0,"text") : depth0),"input_name":__default(require("../js/helpers/get.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"response_inputs") : depths[1]),(data && lookupProperty(data,"index")),{"name":"get","hash":{},"data":data,"loc":{"start":{"line":14,"column":47},"end":{"line":14,"column":78}}}),"input_type":(depths[2] != null ? lookupProperty(depths[2],"input_type") : depths[2]),"code":(depth0 != null ? lookupProperty(depth0,"code") : depth0)},"data":data,"loc":{"start":{"line":13,"column":38},"end":{"line":15,"column":76}}})) != null ? stack1 : "")
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<input type=\"hidden\" name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\" />\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"dk-center\">\n  <input type=\"checkbox\" class=\"dk-check\" />\n  <span class=\"dk-label\"\n    ><label for=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"input_id") : depth0), depth0))
    + "-dk\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"dk_text") : depth0), depth0)) != null ? stack1 : "")
    + "</label></span\n  >\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"question question-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\" aria-live=\"polite\">\n  <legend class=\"question-text\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "_label\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"error_message") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":5,"column":9}}})) != null ? stack1 : "")
    + "  <div class=\"dyngrid\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"category_options") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":19,"column":13}}})) != null ? stack1 : "")
    + "  </div>\n</fieldset>\n\n"
    + ((stack1 = __default(require("../js/helpers/eachkeys.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"passthru") : depth0),{"name":"eachkeys","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":0},"end":{"line":25,"column":13}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"dk") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":14},"end":{"line":32,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});