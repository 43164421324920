var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./error.hbs"),depth0,{"name":"error","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "      mainNav.hide();\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "autostart: true,";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "width: "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"player") : depth0)) != null ? lookupProperty(stack1,"width") : stack1), depth0))
    + ",";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "height: "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"player") : depth0)) != null ? lookupProperty(stack1,"height") : stack1), depth0))
    + ",";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            {file: '"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"src") : depth0), depth0)) != null ? stack1 : "")
    + "'}"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"$last") : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":31},"end":{"line":45,"column":60}}})) != null ? stack1 : "")
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    return ",";
},"14":function(container,depth0,helpers,partials,data) {
    return "        mainNav.show();\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"question question-video\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"error_message") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":4,"column":9}}})) != null ? stack1 : "")
    + "\n  <div id=\"videoplayer\">\n    <div id=\"player\"></div>\n    <input type=\"hidden\" name=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"submit_input_id") : depth0), depth0))
    + "\" value=\"1\" />\n    <input\n      type=\"hidden\"\n      name=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"watched_input_id") : depth0), depth0))
    + "\"\n      id=\"video-watched\"\n      value=\"0\"\n    />\n    <input type=\"hidden\" name=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"error_input_id") : depth0), depth0))
    + "\" id=\"video-error\" value=\"\" />\n  </div>\n</div>\n\n<script>\n  var has_flash = Gryphon.util.has_flash();\n\n  Gryphon.util.getScript('/static/js/jwplayer.js', function () {\n      var plays = "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"plays") : depth0), depth0))
    + ";\n      var total_plays = 0;\n      var watchedVal = page_state.form_data['"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"watched_input_id") : depth0), depth0))
    + "'] || '0';\n      var watchedInput = $('input[name=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"watched_input_id") : depth0), depth0))
    + "\"]');\n\n      watchedInput.val(watchedVal);\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hide_buttons") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":6},"end":{"line":32,"column":13}}})) != null ? stack1 : "")
    + "\n      var player = jwplayer('player');\n\n      player.setup({\n        flashplayer: '/static/js/jwplayer.flash.swf',\n        html5player: '/static/js/jwplayer.html5.js',\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"player") : depth0)) != null ? lookupProperty(stack1,"autoplay") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":39,"column":54}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"player") : depth0)) != null ? lookupProperty(stack1,"width") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":8},"end":{"line":40,"column":59}}})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"player") : depth0)) != null ? lookupProperty(stack1,"height") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":41,"column":62}}})) != null ? stack1 : "")
    + "\n        playlist: [{\n          sources: [\n"
    + ((stack1 = __default(require("../js/helpers/foreach.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"player") : depth0)) != null ? lookupProperty(stack1,"sources") : stack1),{"name":"foreach","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":12},"end":{"line":46,"column":24}}})) != null ? stack1 : "")
    + "          ]\n        }]\n      });\n\n      player.onPlaylistComplete(function () {\n        watchedInput.val('"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"response_codes") : depth0)) != null ? lookupProperty(stack1,"WATCHED") : stack1), depth0))
    + "');\n\n        total_plays++;\n\n        if (plays > 0 && total_plays >= plays) {\n            $('#next_button').click();\n            return;\n        }\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hide_buttons") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":8},"end":{"line":63,"column":15}}})) != null ? stack1 : "")
    + "      });\n\n      player.onError(function () {\n        $('#video-error').val('"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"response_codes") : depth0)) != null ? lookupProperty(stack1,"ERROR") : stack1), depth0))
    + "');\n        mainNav.show();\n        $('#next_button').click();\n      });\n  });\n</script>\n";
},"usePartial":true,"useData":true});