var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div id=\"rankPlacementBlockWide\" class=\"rankPlacementBlock span6\" role=\"list\"></div>\n    <div id=\"rankSelectionBlockWide\" class=\"rankSelectionBlock span6\" role=\"list\"></div>\n    ";
},"3":function(container,depth0,helpers,partials,data) {
    return "\n    <div id=\"rankPlacementBlockSkinny\" class=\"rankPlacementBlock\" role=\"list\"></div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"rank-dk row-fluid\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"identifier") : depth0), depth0))
    + "\" class=\"rankingView\">\n  <div class=\"rank-responses row-fluid\">\n"
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"identifier") : depth0),"wideView",{"name":"ifequal","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":6,"column":16}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../js/helpers/ifequal.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"identifier") : depth0),"skinnyView",{"name":"ifequal","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":17},"end":{"line":8,"column":16}}})) != null ? stack1 : "")
    + "  </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dk") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":2},"end":{"line":12,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});