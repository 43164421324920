export const prepare_preview = function () {
  if (window.context_name !== 'preview') return;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  if (params.ivwBE && params.ivwBE !== '') {
    localStorage.setItem('ivw_be', params.ivwBE);
  }
};

export const load_preview = function () {
  // Show session_summary data in console for Previews [GRYP-5962]
  if (page_state.hasOwnProperty('preview_data')) {
    $.ajax({
      type: 'GET',
      url:
        localStorage.getItem('ivw_be') +
        '/preview/session_summary/' +
        page_state['preview_data'].visa,
      xhrFields: {
        withCredentials: true,
      },
      success: function (session_summary) {
        try {
          console.log(
            new Date().toGMTString() + '\n -- SESSION SUMMARY -- ',
            JSON.parse(session_summary)
          );
          window.session_summary = JSON.parse(session_summary);
          let target = '#previewDebugPanel .accordion-inner';
          if (JsonView) {
            JsonView.renderJSON(
              window.session_summary,
              document.querySelector(target)
            );
            // Tell screen readers to ignore this section
            // Aids in troubleshooting accessibility during local development
            document
              .querySelector('#previewDebugPanel')
              .setAttribute('aria-hidden', true);
          }
        } catch (e) {
          console.error('Error parsing session_summary response\n', e);
        }
      },
    });
  }
};
