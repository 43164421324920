import './modernizr-custom';
import './polyfill';
import './vendor';
/*
 * Global (helper functions, etc)
 * (TODO) Refactor into separate modules
 *
 */
import './survey';

// TODO-Split: removed in POC - but should it be??
// import './polyfill';

/*
 * webpack loaders (style-loader, css-loader, less-loader)
 * base_styles.css is built with a webpack plugin that runs
 * a shell command `grunt less` to convert LESS to CSS
 *
 */
import '../less/base_styles.less';

/*
 * Sets up our global `Gryphon` object
 * and various shared helper functions
 * and libraries between admin and
 * ivw directories
 *
 */
import './base';
import './lib/experiments-visa-regex';
import './lib/analytics';
import './lib/collection';
import './lib/eventlog';
import './lib/form-label-image-click-fix';
import './lib/itherm';
import './lib/jquery.cardsort';
import './lib/jquery.equalisr';
import './lib/jquery.placement';
import './lib/jquery.plcslider';
import './lib/jquery.uniform.defaults';
import './lib/jsonview.bundle';
import './lib/lodash.arraytransforms';
import './lib/navigation';
import './lib/string';
import './lib/util';
import './lib/xor-group';
import './lib/state';
import './templates';

/*
 * Set globals here instead of implicitly relying on `window`
 * so that Webpack can setup our dependency graph
 * ex. global.page_state = {}
 *
 */
global.mainNav = null;
global.segments = null;
global.page_timing_source = 'date';
global.nextQuestionStartTime = null;
global.hide_these = { 'trailing-popup': null };
global.collect_debug_page_timings = false;
global.debug_timings = [];
global.NODE_ENV = process.env.NODE_ENV;
global.EVENTLOG_URL = process.env.EVENTLOG_URL ? process.env.EVENTLOG_URL : '';
global.Modernizr = window.Modernizr || {
  audio: true,
  canvas: true,
  fontface: true,
  inlinesvg: true,
  svg: true,
  video: true,
};
import './lib/globals';

/*
 * Event Handlers (ie. navigation)
 */
import './lib/events';

/*
 * Fetching Questions
 */
import './lib/question';

/*
 * Accessibility Helpers
 */
import './lib/accessibility';

/*
 * Gryphon Widgets
 * (note) uses IIFE format
 *
 */
import './widgets/base';
import './widgets/layout/layout';
import './widgets/single-multiple-colorpicker';
import './widgets/address';
import './widgets/audio';
import './widgets/calendar';
import './widgets/cardsort';
import './widgets/colorpicker';
import './widgets/dropdown';
import './widgets/dyngrid';
import './widgets/grid';
import './widgets/imagehighlight';
import './widgets/imagemap-multiple';
import './widgets/imagemap-single';
import './widgets/matrix';
import './widgets/open';
import './widgets/placement';
import './widgets/popup';
import './widgets/rank';
import './widgets/response-button';
import './widgets/registration';
import './widgets/rule';
import './widgets/scale';
import './widgets/single-multiple';
import './widgets/texthighlight';
import './widgets/thermometer';
import './widgets/upload';
import './widgets/video-dial';
import './widgets/video';
import './widgets/captcha';
