(function ($, widgets) {
  var CardSort = widgets.Widget.extend(
    {
      render: function render() {
        CardSort.__super__.render.call(this);

        var object = this._object;
        var items = $.map(object.category_options, function (category) {
          return category.text[0];
        });
        var bins = $.map(object.response_options, function (option) {
          return decodeHtmlEntities(option.text);
        });
        $('#' + object.input_id + '-cardsort').cardsort({
          items: items,
          bins: bins,
          binWidth: object.bin_width,
          binHeight: object.bin_height,
          itemWidth: object.item_width,
          itemHeight: object.item_height,
          scaleBinItems: object.scale_bin_items,
          unique: object.unique,
          required_text: object.required_text,
        });
        if (object.displaymax) {
          this.setPagedVisibility($('.cardsort-items .cardsort-item'));
        }

        this.handleAccessibilityMenu();

        // Accessible element for previous answers
        this._object.category_options.forEach((option) => {
          if (option.answer) {
            const response = this._object.response_options.find(
              (element) => element.code === option.answer
            );
            if (response) {
              $(`#${option.name}_note`).append(response.text);
            }
          }
        });
      },

      // Used by 'displaymax'
      get_phase_rows: function get_phase_rows() {
        if (this._object.phase_pos === this._object.phase_max) {
          // On the last phase, we return all the phase rows
          return $('.cardsort-items .cardsort-item');
        }
        // Otherwise, just the visible rows (card-sort items)
        return $('.cardsort-items .cardsort-item:visible');
      },

      p_next: function p_next(ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var cur = this._object.phase_pos;
        this.clear_required_message();
        if (this.required_is_satisfied()) {
          this.displaymax_soft_prompts = 0;
          if (cur + 1 > this._object.phase_max) {
            click_next({ key: ev.key, type: ev.type });
          } else {
            this._object.phase_pos++;
            this.setPagedVisibility($('.cardsort-items .cardsort-item'));
            this.update_paged_buttons();
          }
        } else {
          this.display_required_message(
            '#' + this._object.input_id + '-cardsort'
          );
        }
        return false;
      },

      p_back: function p_back(ev) {
        if (
          ev &&
          ev.type === 'keypress' &&
          ev.key !== ' ' &&
          ev.key !== 'Enter'
        ) {
          return;
        }
        var cur = this._object.phase_pos;
        if (cur - 1 < 0) {
          click_back({ key: ev.key, type: ev.type });
        } else {
          this._object.phase_pos--;
          this.setPagedVisibility($('.cardsort-items .cardsort-item'));
          this.update_paged_buttons();
        }
        return false;
      },

      selected_rows_count: function selected_rows_count() {
        var phase_rows = $('.cardsort-bin-list .cardsort-item.sorted');
        return phase_rows.length;
      },

      applyResetToMenu: function (source) {
        var sorted = $(
            '.ui-draggable.sorted[title="' +
              source +
              '"]:not(.cardsort-item-empty)'
          ),
          menu = sorted.find('.accessible-dropdown');
        // Add 'reset' to our accessible menu for a 'sorted' card, if not already present
        if (menu.find('li[data-drop="reset"]').length === 0) {
          menu.append(
            '<li role="menuitem" data-drop="reset" data-source="' +
              source +
              '">Reset ' +
              source +
              '</li>'
          );
        }
        setTimeout(() => {
          // sets the position of the accessibility menu for sorted cards
          sorted
            .find('.icon button[role="button"]')
            .on('keydown', function (e) {
              switch (e.key) {
                case ' ':
                case 'Enter':
                  menu.css(
                    'left',
                    $(this).offset().left + menu.width() / 2 + 4 + 'px'
                  );
                  // sets the top position consistent with the scroll position of the window
                  // and card sort dropper
                  menu.css(
                    'top',
                    $(this).offset().top - window.scrollY + 24 + 'px'
                  );
                  break;
              }
            });
        });
      },
      applyStyleToBins: function () {
        $('.cardsort-bin').each(function () {
          var childNode = $(this).children();
          var items = $(childNode[1]).children().length;

          if (items > 0) {
            $(this).addClass('cardsort-bin-list-with-item');
          } else {
            $(this).removeClass('cardsort-bin-list-with-item');
          }
        });
      },

      handleAccessibilityMenu: function () {
        var self = this;
        var drop, source, draggable, droppable;
        var event = new CustomEvent('load-accessible-menu', {
          detail: {
            actions: {
              selectMenuItem: function (node) {
                // prevent multiple events from firing out of order
                if (
                  drop &&
                  drop === $(node).data('drop') &&
                  source &&
                  source === $(node).data('source')
                ) {
                  return;
                }
                drop = $(node).data('drop');
                source = $(node).data('source');

                if ($(node).data('drop') === 'reset') {
                  // Remove from sorted, and display above card sort
                  draggable = $(
                    '.cardsort-bin.ui-droppable .sorted[title="' + source + '"]'
                  );
                  droppable = $(
                    '.ui-draggable.cardsort-item-empty[title="' + source + '"]'
                  );
                } else {
                  draggable = $(
                    '.ui-draggable[title="' +
                      source +
                      '"]:not(.cardsort-item-empty)'
                  );
                  droppable = $('.cardsort-bin.ui-droppable').has(
                    'label:contains("' + drop + '")'
                  );
                }

                // Using the offset values of the draggable and droppables,
                // simulate a drag and drop event
                var dropOffset = droppable.offset(),
                  dragOffset = draggable.offset(),
                  dx = dropOffset.left - dragOffset.left,
                  dy = dropOffset.top - dragOffset.top;

                draggable.simulate('drag', {
                  dx,
                  dy,
                });
              },
              moveNode: function () {},
            },
            triggers: {
              button: {
                keydown: true,
                click: false,
              },
              menu: {
                keydown: true,
                click: true,
                mouseover: true,
              },
            },
          },
        });
        window.dispatchEvent(event);
        // Both keyboard and mouse sorting will trigger this event
        $(window).on('card::sorted', (evt, source) => {
          self.applyStyleToBins();
          !!source && self.applyResetToMenu(source);
          window.dispatchEvent(event);
        });

        $(window).on('card::reset', () => {
          self.applyStyleToBins();
        });
      },
    },
    {
      types: ['card-sort'],
      views: ['card-sort'],
    }
  );

  CardSort.register();
  widgets.CardSort = CardSort;
})(jQuery, (Gryphon.widgets = Gryphon.widgets || {}));
