var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <legend class=\"question-text\" id=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-label\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"error_message") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":3},"end":{"line":6,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./error.hbs"),depth0,{"name":"error","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"color") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":14,"column":27},"end":{"line":208,"column":13}}})) != null ? stack1 : "")
    + " ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n      <style>\n        /* Sets up user-specified colors which do not belong to our default set of \"supported\" colors */\n        /*\n          NOTE: We are using !important here, because user-defined colors should ALWAYS take priority\n          over system-defined colors\n        */\n\n"
    + ((stack1 = __default(require("../js/helpers/startsWith.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"color") : depth0),"#",{"name":"startsWith","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":79,"column":23}}})) != null ? stack1 : "")
    + "      </style>\n      <li\n        class=\"text-highlight-marker text-highlight-marker-c"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + " text-highlight-marker-"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0)) != null ? stack1 : "")
    + "\"\n        tabindex=\"0\"\n        onkeydown=\"on_change(event)\"\n        role=\"radio\"\n        aria-checked=\"false\"\n        data-marker-code=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\"\n        data-marker-index=\""
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n        data-marker-label=\"c"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n        data-marker-color=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + "\"\n        data-marker-max=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"max_selections") : depth0), depth0))
    + "\"\n        data-marker-min=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"min_selections") : depth0), depth0))
    + "\"\n      >\n        <span\n          class=\"text-highlight-marker-dot text-highlight-marker-dot-c"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n        ></span>\n        <i\n          class=\"text-highlight-marker-icon text-highlight-marker-icon-c"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n        >\n          <svg\n            width=\"18px\"\n            height=\"18px\"\n            viewBox=\"0 0 18 18\"\n            version=\"1.1\"\n            xmlns=\"http://www.w3.org/2000/svg\"\n            xmlns:xlink=\"http://www.w3.org/1999/xlink\"\n          >\n            <title>Group 3</title>\n            <g\n              id=\"05-Image-Highlight"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n              stroke=\"none\"\n              stroke-width=\"1\"\n              fill=\"none\"\n              fill-rule=\"evenodd\"\n            >\n              <g\n                id=\"05-Image-Highlight"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "----Mobile\"\n                transform=\"translate(-158.000000, -236.000000)\"\n              >\n                <g\n                  id=\"Group-3"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n                  transform=\"translate(158.000000, 237.000000)\"\n                >\n                  <path\n                    d=\"M12.3636364,12.7866915 L13.9090909,12.7866915 L13.9090909,4.63636364 C13.9090909,3.78636364 13.2136364,3.09090909 12.3636364,3.09090909 L4.185252,3.09090909 L4.185252,4.63636364 L12.3636364,4.63636364 L12.3636364,12.7866915 Z M4.63636364,12.3636364 L4.63636364,0 L3.09090909,0 L3.09090909,3.09090909 L0,3.09090909 L0,4.63636364 L3.09090909,4.63636364 L3.09090909,12.3636364 C3.09090909,13.2136364 3.78636364,13.9090909 4.63636364,13.9090909 L12.3636364,13.9090909 L12.3636364,17 L13.9090909,17 L13.9090909,13.9090909 L17,13.9090909 L17,12.3636364 L4.63636364,12.3636364 Z\"\n                    id=\"Shape"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n                    fill=\"#433c54\"\n                    fill-rule=\"nonzero\"\n                  ></path>\n                  <line\n                    x1=\"4.63636364\"\n                    y1=\"12.3636364\"\n                    x2=\"17\"\n                    y2=\"-1.13686838e-13\"\n                    id=\"Path-2"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n                    stroke=\"#433c54\"\n                  ></line>\n                </g>\n              </g>\n            </g>\n          </svg>\n        </i>\n        <!-- prettier-ignore -->\n        <p class=\"text-highlight-marker-caption text-highlight-marker-caption-c"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "</p>\n      </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        /* Escape hex values */\n        .text-highlight-marker.text-highlight-marker-\\"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " {\n          border: 1px solid "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " !important;\n          color: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " !important;\n        }\n        .text-highlight-marker.text-highlight-marker-\\"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " svg,\n        .text-highlight-marker.text-highlight-marker-\\"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " svg path {\n          fill: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " !important;\n        }\n        .text-highlight-marker.text-highlight-marker-\\"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " svg line {\n          stroke: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " !important;\n        }\n        .text-highlight-marker.text-highlight-marker-\\"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ":hover,\n        .text-highlight-marker.text-highlight-marker-\\"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ".text-highlight-selected {\n          background: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " !important;\n          border: 1px solid "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " !important;\n          color: #fff !important;\n        }\n        .text-highlight-marker.text-highlight-marker-\\"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ":hover svg,\n        .text-highlight-marker.text-highlight-marker-\\"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ":hover svg path,\n        .text-highlight-marker.text-highlight-marker-\\"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ".text-highlight-selected svg,\n        .text-highlight-marker.text-highlight-marker-\\"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ".text-highlight-selected svg path {\n          fill: #fff !important;\n        }\n        .text-highlight-marker.text-highlight-marker-\\"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ":hover svg line,\n        .text-highlight-marker.text-highlight-marker-\\"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ".text-highlight-selected svg line {\n          stroke: #fff !important;\n        }\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        .text-highlight-marker.text-highlight-marker-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " {\n          border: 1px solid "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " !important;\n          color: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " !important;\n        }\n        .text-highlight-marker.text-highlight-marker-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " svg,\n        .text-highlight-marker.text-highlight-marker-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " svg path {\n          fill: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " !important;\n        }\n        .text-highlight-marker.text-highlight-marker-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " svg line {\n          stroke: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " !important;\n        }\n        .text-highlight-marker.text-highlight-marker-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ":hover,\n        .text-highlight-marker.text-highlight-marker-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ".text-highlight-selected {\n          background: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " !important;\n          border: 1px solid "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + " !important;\n          color: #fff !important;\n        }\n        .text-highlight-marker.text-highlight-marker-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ":hover svg,\n        .text-highlight-marker.text-highlight-marker-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ":hover svg path,\n        .text-highlight-marker.text-highlight-marker-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ".text-highlight-selected svg,\n        .text-highlight-marker.text-highlight-marker-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ".text-highlight-selected svg path {\n          fill: #fff !important;\n        }\n        .text-highlight-marker.text-highlight-marker-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ":hover svg line,\n        .text-highlight-marker.text-highlight-marker-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"color") : depth0), depth0))
    + ".text-highlight-selected svg line {\n          stroke: #fff !important;\n        }\n";
},"10":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <li\n        class=\"text-highlight-marker text-highlight-marker-c"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n        tabindex=\"0\"\n        onkeydown=\"on_change(event)\"\n        role=\"radio\"\n        aria-checked=\"false\"\n        data-marker-code=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "\"\n        data-marker-index=\""
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n        data-marker-label=\"c"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n        data-marker-color\n        data-marker-max=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"max_selections") : depth0), depth0))
    + "\"\n        data-marker-min=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"min_selections") : depth0), depth0))
    + "\"\n      >\n        <span\n          class=\"text-highlight-marker-dot text-highlight-marker-dot-c"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n        ></span>\n        <i\n          class=\"text-highlight-marker-icon text-highlight-marker-icon-c"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n        >\n          <svg\n            width=\"18px\"\n            height=\"18px\"\n            viewBox=\"0 0 18 18\"\n            version=\"1.1\"\n            xmlns=\"http://www.w3.org/2000/svg\"\n            xmlns:xlink=\"http://www.w3.org/1999/xlink\"\n          >\n            <title>Group 3</title>\n            <g\n              id=\"05-Image-Highlight"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n              stroke=\"none\"\n              stroke-width=\"1\"\n              fill-rule=\"evenodd\"\n            >\n              <g\n                id=\"05-Image-Highlight"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "----Mobile\"\n                transform=\"translate(-158.000000, -236.000000)\"\n              >\n                <g\n                  id=\"Group-3"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n                  transform=\"translate(158.000000, 237.000000)\"\n                >\n                  <path\n                    d=\"M12.3636364,12.7866915 L13.9090909,12.7866915 L13.9090909,4.63636364 C13.9090909,3.78636364 13.2136364,3.09090909 12.3636364,3.09090909 L4.185252,3.09090909 L4.185252,4.63636364 L12.3636364,4.63636364 L12.3636364,12.7866915 Z M4.63636364,12.3636364 L4.63636364,0 L3.09090909,0 L3.09090909,3.09090909 L0,3.09090909 L0,4.63636364 L3.09090909,4.63636364 L3.09090909,12.3636364 C3.09090909,13.2136364 3.78636364,13.9090909 4.63636364,13.9090909 L12.3636364,13.9090909 L12.3636364,17 L13.9090909,17 L13.9090909,13.9090909 L17,13.9090909 L17,12.3636364 L4.63636364,12.3636364 Z\"\n                    id=\"Shape"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n                  ></path>\n                  <line\n                    x1=\"4.63636364\"\n                    y1=\"12.3636364\"\n                    x2=\"17\"\n                    y2=\"-1.13686838e-13\"\n                    id=\"Path-2"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\"\n                  ></line>\n                </g>\n              </g>\n            </g>\n          </svg>\n        </i>\n        <!-- prettier-ignore -->\n        <p class=\"text-highlight-marker-caption text-highlight-marker-caption-c"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "</p>\n      </li>\n      ";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <!-- prettier-ignore -->\n        <span\n          id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n          class=\"highlight-response\"\n          data-widget-id=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"element_id") : depths[1]), depth0))
    + "\"\n          data-name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n          data-varname=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"varname") : depth0), depth0))
    + "\"\n          data-selectable=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"selectable") : depth0), depth0))
    + "\"\n          >"
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? lookupProperty(stack1,"0") : stack1), depth0)) != null ? stack1 : "")
    + " </span>\n        <input\n          id=\"r-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n          class=\"image-highlight-input\"\n          type=\"hidden\"\n          name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n          value=\""
    + alias2(__default(require("../js/helpers/answer.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"name") : depth0),(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"answer","hash":{},"data":data,"loc":{"start":{"line":227,"column":17},"end":{"line":227,"column":37}}}))
    + "\"\n        />\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = __default(require("../js/helpers/partial.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"dk-partial",{"name":"partial","hash":{"index":(data && lookupProperty(data,"index")),"dk_id":(depth0 != null ? lookupProperty(depth0,"dk_id") : depth0),"dk_value":997,"dk_text":(depth0 != null ? lookupProperty(depth0,"dont_know") : depth0)},"data":data,"loc":{"start":{"line":232,"column":15},"end":{"line":233,"column":32}}})) != null ? stack1 : "")
    + " ";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <th scope=\"col\" id=\"label_c"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "</th>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <td headers=\"label_c"
    + alias2(alias1((data && lookupProperty(data,"index")), depth0))
    + "\">\n              <textarea\n                rows=\"6\"\n                cols=\"40\"\n                class=\"input textarea\"\n                name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"input_name") : depth0), depth0))
    + "\"\n              ></textarea>\n            </td>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <input type=\"hidden\" name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\" />\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"question question-text-highlight text-highlight\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"read_only") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":7,"column":13}}})) != null ? stack1 : "")
    + "   <div class=\"text-highlight-widget text-highlight-block\">\n    <ul\n      class=\"text-highlight-markers text-highlight-gradient\"\n      role=\"radiogroup\"\n      aria-labelledby=\"text-highlight\"\n    >\n      "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"highlights") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":6},"end":{"line":208,"column":23}}})) != null ? stack1 : "")
    + "\n    </ul>\n    <div class=\"text-highlight-board\">\n      <div class=\"inner\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"category_options") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":212,"column":8},"end":{"line":229,"column":17}}})) != null ? stack1 : "")
    + "      </div>\n    </div>\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"dk") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":232,"column":4},"end":{"line":233,"column":40}}})) != null ? stack1 : "")
    + "\n    <div class=\"answers answers-table\" style=\"display: none\">\n      <table class=\"grid grid-table-mode\">\n        <tbody>\n          <tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"highlights") : depth0),{"name":"each","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":238,"column":12},"end":{"line":240,"column":21}}})) != null ? stack1 : "")
    + "          </tr>\n        </tbody>\n        <tbody>\n          <tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"highlights") : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":245,"column":12},"end":{"line":254,"column":21}}})) != null ? stack1 : "")
    + "          </tr>\n        </tbody>\n      </table>\n    </div>\n"
    + ((stack1 = __default(require("../js/helpers/eachkeys.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"passthru") : depth0),{"name":"eachkeys","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":259,"column":4},"end":{"line":261,"column":17}}})) != null ? stack1 : "")
    + "  </div>\n</fieldset>\n\n\n";
},"usePartial":true,"useData":true,"useDepths":true});