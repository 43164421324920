var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <div class=\"validation-result alert alert-error\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"error_message") : depth0), depth0))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p class=\"captcha-text\">"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"privacy_and_terms_of_service") : depth0), depth0)) != null ? stack1 : "")
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"captcha-widget\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"error_message") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":3,"column":9}}})) != null ? stack1 : "")
    + "  <input\n    type=\"hidden\"\n    name=\""
    + container.escapeExpression(alias2((depth0 != null ? lookupProperty(depth0,"token_id") : depth0), depth0))
    + "\"\n  />\n</div>\n"
    + ((stack1 = __default(require("../js/helpers/ifindexof.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"version") : depth0),"v3",">",-1,{"name":"ifindexof","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":0},"end":{"line":11,"column":14}}})) != null ? stack1 : "")
    + "<script src=\""
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"RECAPTCHA_DOMAIN") : depth0), depth0)) != null ? stack1 : "")
    + "/recaptcha/"
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"RECAPTCHA_SCRIPT") : depth0), depth0)) != null ? stack1 : "")
    + "?"
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"RECAPTCHA_SCRIPT_PARAMS") : depth0), depth0)) != null ? stack1 : "")
    + "\" async defer></script>\n<div id=\"captcha_v2_element\" class=\"g-recaptcha\" data-sitekey=\""
    + ((stack1 = alias2((depth0 != null ? lookupProperty(depth0,"RECAPTCHA_SITE_KEY") : depth0), depth0)) != null ? stack1 : "")
    + "\" data-callback=\"onVerifyCaptcha\"></div>\n<br>\n\n";
},"useData":true});