var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <legend class=\"question-text\" id=\""
    + container.escapeExpression(alias1((depth0 != null ? lookupProperty(depth0,"element_id") : depth0), depth0))
    + "-label\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0)) != null ? stack1 : "")
    + "</legend>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"error_message") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":6,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./error.hbs"),depth0,{"name":"error","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <a href=\"\" style=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"style") : depth0), depth0))
    + "\" tabindex=\"0\" onkeydown=\"on_change(event)\" role=\"checkbox\" aria-checked=\"false\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"read_only") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":114},"end":{"line":12,"column":76}}})) != null ? stack1 : "")
    + ">\n        <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"read_only") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":17,"column":19}}})) != null ? stack1 : "")
    + "      </a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " class=\"selected inactive\" onclick=\"return false\" ";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input type=\"hidden\" name=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"input_name") : depth0), depth0))
    + "\" "
    + ((stack1 = __default(require("../js/helpers/ifanswerexists.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"input_name") : depth0),{"name":"ifanswerexists","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":15,"column":51},"end":{"line":16,"column":48}}})) != null ? stack1 : "")
    + ">\n";
},"8":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " value=\""
    + container.escapeExpression(__default(require("../js/helpers/answer.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"input_name") : depth0),{"name":"answer","hash":{},"data":data,"loc":{"start":{"line":15,"column":89},"end":{"line":15,"column":110}}}))
    + "\" ";
},"10":function(container,depth0,helpers,partials,data) {
    return "\n          value=\"unselected\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"question question-imagemapmulti\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"read_only") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "  <div class=\"image-map multi\" style=\"width:"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"img_width") : depth0), depth0))
    + "px; height:"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"img_height") : depth0), depth0))
    + "px; background-image:url("
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"img_src") : depth0), depth0))
    + ");\">\n    <div class=\"image-area\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"areas") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":19,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</fieldset>\n";
},"usePartial":true,"useData":true});