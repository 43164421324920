var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"audioplayer\">\n  <object\n    classid=\"clsid:d27cdb6e-ae6d-11cf-96b8-444553540000\"\n    codebase=\"http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=8,0,0,0\"\n    width=\"300\"\n    height=\"70\"\n    align=\"middle\"\n  >\n    <param name=\"allowScriptAccess\" value=\"sameDomain\" />\n    <param name=\"allowFullScreen\" value=\"false\" />\n    <param\n      name=\"movie\"\n      value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"static_prefix") || (depth0 != null ? lookupProperty(depth0,"static_prefix") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"static_prefix","hash":{},"data":data,"loc":{"start":{"line":13,"column":13},"end":{"line":13,"column":30}}}) : helper)))
    + "/widgets/media/MP3Widget.swf?audioUrl="
    + alias4(((helper = (helper = lookupProperty(helpers,"filename") || (depth0 != null ? lookupProperty(depth0,"filename") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filename","hash":{},"data":data,"loc":{"start":{"line":13,"column":68},"end":{"line":13,"column":80}}}) : helper)))
    + "&amp;showProgressBar="
    + alias4(((helper = (helper = lookupProperty(helpers,"showProg") || (depth0 != null ? lookupProperty(depth0,"showProg") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"showProg","hash":{},"data":data,"loc":{"start":{"line":13,"column":101},"end":{"line":13,"column":113}}}) : helper)))
    + "\"\n    />\n    <param name=\"quality\" value=\"best\" />\n    <param name=\"bgcolor\" value=\"#ffffff\" />\n    <embed\n      src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"static_prefix") || (depth0 != null ? lookupProperty(depth0,"static_prefix") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"static_prefix","hash":{},"data":data,"loc":{"start":{"line":18,"column":11},"end":{"line":18,"column":28}}}) : helper)))
    + "/widgets/media/MP3Widget.swf?audioUrl="
    + alias4(((helper = (helper = lookupProperty(helpers,"filename") || (depth0 != null ? lookupProperty(depth0,"filename") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"filename","hash":{},"data":data,"loc":{"start":{"line":18,"column":66},"end":{"line":18,"column":78}}}) : helper)))
    + "&amp;showProgressBar="
    + alias4(((helper = (helper = lookupProperty(helpers,"showProg") || (depth0 != null ? lookupProperty(depth0,"showProg") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"showProg","hash":{},"data":data,"loc":{"start":{"line":18,"column":99},"end":{"line":18,"column":111}}}) : helper)))
    + "\"\n      quality=\"best\"\n      bgcolor=\"#ffffff\"\n      width=\"300\"\n      height=\"70\"\n      align=\"middle\"\n      allowScriptAccess=\"sameDomain\"\n      allowFullScreen=\"false\"\n      type=\"application/x-shockwave-flash\"\n      pluginspage=\"http://www.macromedia.com/go/getflashplayer\"\n    />\n  </object>\n  <input type=\"hidden\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"input_id") || (depth0 != null ? lookupProperty(depth0,"input_id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"input_id","hash":{},"data":data,"loc":{"start":{"line":30,"column":29},"end":{"line":30,"column":41}}}) : helper)))
    + "\" value=\"1\" />\n</div>\n";
},"useData":true});